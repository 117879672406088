import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ParticipantForm } from '../../../components/forms/participant';
import Layout from '../../../components/layout';
import { uploadFile } from '../../../services/file.service';
import { addParticipant } from '../../../services/participant.service';
import { showError, showSuccess } from '../../../utils/helper';

export const ParticipantAdd: React.FunctionComponent = () => {
    let fileId = 0
    const history = useHistory()
    const formSubmit = async (data: any) => {
        if (fileId == 0 && data.profile_pic && data.profile_pic instanceof File) {
            const formData = new FormData()
            formData.set('file', data.profile_pic)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                fileId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }

        }

        if (fileId != 0) {
            data.profile_pic = fileId
        }
        const response = await addParticipant(data)
        if (!response.error) {
            showSuccess("Participant added successfully")
            fileId = 0
            setTimeout(() => {
                history.push({
                    pathname: '/participants/list'
                })
            }, 100)
            return true
        } else {
            showError(response.message)
            return false
        }

    }
    return (
        <Layout>
            <Container className="w-50 mr-auto container" >
                <h2 className="mb-5">Add Participant</h2>
                {
                    <ParticipantForm
                        onSubmit={async (formValues) => await formSubmit(formValues)} type="Add"></ParticipantForm>
                }


            </Container>
        </Layout >
    )

}
