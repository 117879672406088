import React, { ReactElement, useEffect, useState } from 'react'
import DataTable from 'react-data-table-component'
import { getFormattedOptionsData } from '../../../utils/helper'
import Loader from '../../loader'
import SelectOption from '../selectoption'
import * as Icon from 'react-bootstrap-icons';
let timer: any = null

interface Props {
  title?: string
  columns: Array<any>
  data: Array<any>
  filterOptions?: Array<any>
  isFilterable?: boolean
  paginationPerPage?: number
  paginationTotalRows: number
  onSort?: (columnName: any, sortDirection: string) => void
  onFilter?: (filterColumn: string, filterText: string) => void
  onPageChange?: (page: number) => void
  defaultSortField?: string
  defaultSortAsc?: boolean
  loading: boolean
  pagination?: boolean
  sortServer?: boolean
  filterOpt?: any
  selectableRows?: boolean
  onSelectedRowsChange?: (selectedRows: any[]) => void
  paginationDefaultPage?: number
  subHeaderComponent?: any
}
export const TableWithFPS = (props: Props): ReactElement => {
  const {
    title,
    columns,
    data,
    filterOptions,
    isFilterable,
    paginationPerPage,
    onSort,
    onFilter,
    defaultSortField,
    defaultSortAsc,
    paginationTotalRows,
    loading,
    onPageChange,
    pagination,
    sortServer,
    filterOpt,
    selectableRows,
    onSelectedRowsChange,
    paginationDefaultPage,
    subHeaderComponent
  } = props
  const [filterText, setFilterText] = useState(filterOpt ? filterOpt.filterText : "")
  const filterOptionsData = filterOptions && getFormattedOptionsData(filterOptions)

  useEffect(() => {
    if (filterOpt) {
      setFilterText(filterOpt.filterText)
    }
  }, [filterOpt])
  const onFilterHandle = (filterColumn: string, filterText: string) => {
    setFilterText(filterText)
    if (filterText == "") {
      onFilter && onFilter(filterColumn, "")
      return
    }

    const lastSearchedKey = filterText
    if (timer) clearTimeout(timer)
    if (filterText.length >= 3) {
      timer = setTimeout(async () => {
        onFilter && onFilter(filterColumn, lastSearchedKey)
      }, 300)
    }


  }

  return (
    <DataTable
      sortIcon={
        <Icon.ArrowDown />
      }
      title={title || ''}
      noHeader={title ? false : true}
      columns={columns}
      data={data}
      progressPending={loading}
      progressComponent={<Loader />}
      pagination={pagination != undefined ? pagination : true}
      paginationPerPage={paginationPerPage || 10}
      paginationTotalRows={paginationTotalRows}
      paginationServer
      paginationDefaultPage={paginationDefaultPage}
      paginationComponentOptions={{
        noRowsPerPage: true,
        selectAllRowsItem: false,
        selectAllRowsItemText: 'All',
      }}
      subHeader={subHeaderComponent || (isFilterable && (filterText?.trim().length > 0 || (data && data.length > 0)))}
      subHeaderComponent={
        subHeaderComponent ||
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          {filterOptions && filterOptions?.length > 0 && (
            <FilterComponent
              filterOptions={filterOptionsData}
              onFilter={(filterColumn: string, value: string) => {
                onFilterHandle(filterColumn, value)
              }}
              filterText={filterText}
              defaultValue={(filterOpt && filterOpt["column"] && getFormattedOptionsData(filterOpt["column"])[0]) || filterOptionsData[0]}
            />
          )}
        </div>
      }
      onSort={(column, sortDirection) => {
        onSort && onSort(column.selector, sortDirection)
      }}
      sortServer={sortServer != undefined ? sortServer : true}
      striped={true}
      highlightOnHover={true}
      defaultSortField={defaultSortField}
      defaultSortAsc={defaultSortAsc}
      onChangePage={(page) => {
        if (page != 0 && paginationDefaultPage != page) {
          onPageChange && onPageChange(page)

        }
      }}
      noDataComponent={'No data to show'}
      selectableRows={selectableRows}
      onSelectedRowsChange={({ selectedRows }
        : { selectedRows: any[] }
      ) => {
        const idArray: any[] = []
        selectedRows.map((row) => {
          idArray.push(row.id)
        })
        onSelectedRowsChange && onSelectedRowsChange(idArray)
      }}
    />
  )
}

const FilterComponent = ({
  filterText,
  onFilter,
  filterOptions,
  defaultValue
}: {
  filterText: string
  onFilter: any
  filterOptions: Array<any>,
  defaultValue: any
}) => {
  const [filterColumn, setFilterColumn] = useState(defaultValue)
  useEffect(() => {
    setFilterColumn(filterColumn)
  }, [defaultValue != filterColumn])

  return (
    <>
      <div className="mb-4 mt-4 d-flex">
        <SelectOption
          isSearchable={false}
          options={filterOptions}
          value={filterColumn}
          onChange={(value: any) => {
            setFilterColumn(value)
            onFilter(value.value, "")
          }
          }
        />
        <div className="filter ml-4">
          <input
            className="form-control"
            id="search"
            autoComplete={'off'}
            type="text"
            placeholder={`Filter By ${filterColumn.label}`}
            aria-label="Search Input"
            value={filterText}
            onChange={(e) => {
              onFilter(filterColumn.value, e.target.value)
            }}
          />
          {
            filterText && filterText.length > 0 &&
            <button type="button" onClick={() => {
              if (filterText != "") {
                onFilter(filterColumn.value, "")
              }
            }}>
              X
          </button>
          }
        </div>

      </div>
    </>
  )
}
