import cookie from 'js-cookie'

export const setCookie = (key: string, value: string): void => {
  cookie.set(key, value, {
    path: '/',
  })
}

export const removeCookie = (key: string): void => {
  cookie.remove(key)
}

export const getCookie = (key: string): string => {
  return cookie.get(key) || ''
}
