import React from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../common';
import { Button } from 'react-bootstrap';
import Loader from '../../loader';
interface IUser {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    onSubmit: (values: any) => Promise<boolean>
}

const getSchema = () => {
    const schemaObj: any = {
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        password: Yup.string().required("Password is required").min(5),
    }
    return Yup.object().shape(schemaObj);
}

export const AccountForm = (props: IUser) => {
    const { email, firstName, lastName, password, onSubmit } = props
    const UserSchema = getSchema()
    return (<Formik
        initialValues={{
            email: email || "",
            firstName: firstName || "",
            lastName: lastName || "",
            password: password || "",
        }}
        validationSchema={UserSchema}
        onSubmit={async (values, actions) => {
            try {
                const valid = await UserSchema.isValid(values);

                if (valid) {
                    const res: boolean = await onSubmit(values)
                    actions.setSubmitting(false);
                    if (res) {
                        actions.resetForm({
                            values: {
                                // the type of `values` inferred to be Blog
                                email: '',
                                firstName: '',
                                lastName: '',
                                password: '',
                            },
                            // you can also set the other form states here
                        });
                        // window.location.reload()
                    }
                }
                console.log(valid, values);
            } catch (error) {
                console.log(error);
            }

        }}
    >
        {({
            isSubmitting,
            /* and other goodies */
        }) => (
                <div className="mb-3">
                    <Form>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"First Name"} name={"firstName"} disabled placeholder="First Name" />
                        </div>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"Last Name"} name={"lastName"} disabled placeholder="Last Name" />
                        </div>
                        <div>
                            <InputTextField label={"Email"} name={"email"} placeholder="Email" disabled type={"email"} />
                        </div>

                        <div>
                            <InputTextField label={"Password"} type={"password"} name={"password"} placeholder="Password" />
                        </div>

                        {isSubmitting
                            ?
                            <Loader />
                            :
                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">Submit</Button>
                        }

                    </Form>
                </div>
            )}
    </Formik>)
}