import { execute, HTTPMethod } from './http.service'

export const uploadFile = async (fileBody: any) => {
  const response = await execute(
    `protected/file/upload`,
    HTTPMethod.POST,
    fileBody,
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  )
  return response
}
