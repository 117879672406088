import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import Layout from '../../components/layout';

export const Dashboard: FunctionComponent = () => {
    return (
        <Layout>
            <Container>Dashboard</Container>
        </Layout>
    )
}