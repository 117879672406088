import React, { FunctionComponent } from 'react'
import { Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { useLocation } from 'react-router-dom'
import { isAuthenticated, USER_STORAGE_KEY } from '../../services/auth.service'
import { isAdmin, isManager } from '../../services/role.service'
import { getCookie } from '../../utils/cookie'

const Header: FunctionComponent = () => {
  const location = useLocation()
  let userName = '', companyId = ""
  try {
    userName = JSON.parse(getCookie(USER_STORAGE_KEY)).firstName
    companyId = (JSON.parse(getCookie(USER_STORAGE_KEY)).UserCompanies[0])?.company_id
  } catch (e) { }
  return (
    <Navbar bg="light" expand="lg" className="nav-admin">
      <Navbar.Brand href="#home">Admin</Navbar.Brand>
      <Nav className="ml-auto">
        {isAuthenticated() && (
          <div className="navDiv">
            {/* <Nav.Link href="/dashboard" active={location.pathname.indexOf("/dashboard") != -1}>Dashboard</Nav.Link> */}
            <Nav.Link href="/users" active={location.pathname.indexOf("/users") != -1}>Users</Nav.Link>
            <Nav.Link href="/videos" active={location.pathname.indexOf("/videos") != -1}>Videos</Nav.Link>

            {
              isManager() ?
                <Nav.Link href={`/company/edit/${companyId}`} active={location.pathname.indexOf("/company") != -1 && location.pathname.indexOf("/featured-videos") == -1}>Company Profile</Nav.Link>
                :
                <Nav.Link href="/company" active={location.pathname.indexOf("/company") != -1 && location.pathname.indexOf("/featured-videos") == -1}>Companies</Nav.Link>
            }
            {isAdmin() && <Nav.Link href="/videomoderation" active={location.pathname.indexOf("/videomoderation") != -1}>Video Moderation</Nav.Link>}
            {isAdmin() && <Nav.Link href="/participants" active={location.pathname.indexOf("/participants") != -1}>Participants</Nav.Link>}
            <NavDropdown title="Featured Videos" id="collasible-nav-dropdown" active={location.pathname.indexOf("/featured-videos") != -1}>
              {isAdmin() && (
                <NavDropdown.Item href="/featured-videos/category" active={location.pathname.indexOf("/featured-videos/category") != -1}>
                  Category
                </NavDropdown.Item>
              )}
              <NavDropdown.Item href="/featured-videos/company" active={location.pathname.indexOf("/featured-videos/company") != -1}>
                Company
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title={userName} id="collasible-nav-dropdown">
              <NavDropdown.Item href="/profile/edit">Edit Profile</NavDropdown.Item>
              <NavDropdown.Item href="/logout">Logout</NavDropdown.Item>
            </NavDropdown>
          </div>
        )}
      </Nav>
    </Navbar>
    // <Nav>
    //     <ul>
    //         <li>
    //             <Link to="/login">Login</Link>
    //         </li>
    //         <li>
    //             <Link to="/dashboard">Dashboard</Link>
    //         </li>
    //         <li>
    //             <Link to="/users">User</Link>
    //         </li>
    //         <li>
    //             <Link to="/videos">Video</Link>
    //         </li>
    //     </ul>
    // </Nav>
  )
}

export default Header
