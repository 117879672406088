import algoliasearch from 'algoliasearch/lite'
import Axios from 'axios'
import React, { FunctionComponent, useEffect, useState } from 'react'
import {
  InstantSearch,
  SearchBox,
  Pagination,
  ClearRefinements,
  RefinementList,
  Configure,
  connectHits,
} from 'react-instantsearch-dom'
import { execute, HTTPMethod } from '../../../services/http.service'
import { getVideosByIds } from '../../../services/video.service'

type HitsProps = {
  hits: any
}

const Hits = (props: HitsProps) => {
  const { hits } = props
  const [data, setData] = useState([])
  const fetchData = async (ids: Array<number>) => {
    console.log('videoIds to fetch', ids)

    const response = await getVideosByIds(ids)
    setData(response.data)
  }
  useEffect(() => {
    const videoIds = hits.map((hit: any) => hit.id)
    fetchData(videoIds)
  }, [props.hits])
  return (
    <ol>
      {data && data.map((hit: any) => (
        <li key={hit.objectID}>{hit.title}</li>
      ))}
    </ol>
  )
}

const CustomHits = connectHits(Hits)
const ALGOLIA_APP_ID = (() => process.env.REACT_APP_ALGOLIA_APP_ID || '')()
const ALGOLIA_APP_KEY = (() => process.env.REACT_APP_ALGOLIA_KEY || '')()

const searchClient = algoliasearch(ALGOLIA_APP_ID, ALGOLIA_APP_KEY)

type Props = {
  query: any
}

const VideoSearchResults: FunctionComponent<Props> = (props: Props) => {
  console.log(props.query)
  return (
    <div className="ais-InstantSearch">
      <h1>React InstantSearch e-commerce demo</h1>
      <InstantSearch indexName="video" searchClient={searchClient}>
        <Configure hitsPerPage={3} />
        <SearchBox defaultRefinement={props.query} />
        <div className="left-panel">
          <ClearRefinements />
          <h2>Category</h2>
          <RefinementList attribute="category_name" />
        </div>
        <div className="right-panel">
          <CustomHits />
          <Pagination />
        </div>
      </InstantSearch>
    </div>
  )
}
export default VideoSearchResults
