import React, { useEffect, useState } from 'react'
import { MultiSelect } from '../common/multi-select'
import { categories } from '../../services/category.service'
import { ErrorMessage, Field } from 'formik'
import { showError } from '../../utils/helper'

type TOptions = {
  value: any
  label: any
}

interface Props {
  onChange: (value: any) => void
  name: string
  selectedOptions?: number
}

const Categories = (props: Props) => {
  const [options, setOptions] = useState<TOptions[]>()
  const [selected, setSelected] = useState()
  const { onChange, name, selectedOptions } = props

  useEffect(() => {
    async function fetchInitialData() {
      const response = await categories()
      if (response.error === true) {
        showError(response.message)
        return
      }
      createOptions(response.data)
    }

    fetchInitialData()
  }, [])

  useEffect(() => {
    createSelectedOptions(options, selectedOptions)
  }, [selectedOptions])

  const createOptions = (data: Array<unknown>) => {
    const defaultOptions = data.map((element: any) => {
      return {
        value: element.id,
        label: element.name,
      }
    })

    setOptions(defaultOptions)

    if (selectedOptions != undefined) {
      createSelectedOptions(defaultOptions, selectedOptions)
    }
  }

  const createSelectedOptions = (defaultOptions: any, selectedOptions = 0) => {
    if (defaultOptions) {
      const selected = defaultOptions.filter((option: any) => {
        return option.value == selectedOptions
      })
      setSelected(selected)
      console.log(selected)
    }
  }

  return (
    // <Field name={name}>
    <div className="companys">
      <label>Categories selection</label>
      <MultiSelect
        isMultiSelect={false}
        options={options}
        value={selected}
        onCreate={(value) => {
          console.log('Creating', value)
        }}
        onChange={(company) => {
          onChange(company && company.value || '')
          setSelected(company)
        }}
        creatable={false}

      />
      <div className="text-danger">
        <ErrorMessage name={name} />
      </div>

    </div>
    // </Field>
  )
}

export default Categories
