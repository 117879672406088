import { execute, HTTPMethod } from './http.service'
export const participants = async () => {
  const response = await execute(`protected/participants`)
  return response
}

export const getParticipantList = async (queryParam = '') => {
  const query = queryParam != '' ? `${queryParam}` : ''
  const response = await execute(`/protected/participants/paginate${query}`)
  return response
}

export const addParticipant = async (participant: any) => {
  const response = await execute(
    `protected/participants`,
    HTTPMethod.POST,
    participant
  )
  return response
}

export const getParticipantById = async (userId: number) => {
  const response = await execute(`/protected/participants/${userId}`)
  return response
}

export const editParticipant = async (reqBody: object = {}, userId: number) => {
  const response = await execute(
    `/protected/participants/${userId}`,
    HTTPMethod.PUT,
    reqBody
  )
  return response
}

export const deleteParticipant = async (reqBody: object = {}) => {
  const response = await execute(
    `/protected/participants`,
    HTTPMethod.DELETE,
    reqBody
  )
  return response
}
