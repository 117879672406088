import React, { Component } from 'react'
import CreatableSelect from 'react-select/creatable'
import Select from 'react-select'

interface Props {
  isLoading?: boolean
  isMultiSelect?: boolean
  options?: Array<object>
  value?: object
  onChange: (value: any, action: any) => void
  onCreate: (value: any) => void
  creatable?: boolean
}

export const MultiSelect = (props: Props) => {
  const {
    isLoading,
    options,
    value,
    onChange,
    onCreate,
    isMultiSelect,
    creatable,
  } = props

  const handleChange = (value: any, action: any) => {
    onChange(value, action)
  }
  const handleCreate = (value: any) => {
    onCreate(value)
  }

  return creatable ? (
    <CreatableSelect
      isMulti={isMultiSelect || false}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      value={value}
    />
  ) : (
    <Select
      isMulti={isMultiSelect || false}
      isClearable
      isDisabled={isLoading}
      isLoading={isLoading}
      onChange={handleChange}
      onCreateOption={handleCreate}
      options={options}
      value={value}
      className='multiselect'
    />
  )
}
