import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ParticipantForm } from '../../../components/forms/participant';
import Layout from '../../../components/layout';
import { uploadFile } from '../../../services/file.service';
import { editParticipant, getParticipantById } from '../../../services/participant.service';
import { showError, showSuccess } from '../../../utils/helper';

export const ParticiapantEdit: React.FunctionComponent = (props: any) => {
    const history = useHistory()
    const { id: userId } = props.match.params
    let fileId = 0
    const [fetched, setFetched] = useState(false)
    const [user, setUser] = useState({
        "id": 0,
        "name": "",
        "profile_image": { "path": "" },
        "email": "",
        "phone_number": ""
    })
    useEffect(() => {
        getUser()
    }, [userId])
    const getUser = async () => {
        const response = await getParticipantById(userId)
        if (!response.error) {
            setUser(response.data)
        } else {
            showError(response.message)
        }
        setFetched(true)
    }

    const formSubmit = async (data: any) => {
        if (fileId == 0 && data.profile_pic && data.profile_pic instanceof File) {
            const formData = new FormData()
            formData.set('file', data.profile_pic)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                fileId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }

        }

        if (fileId != 0) {
            data.profile_pic = fileId
        } else {
            delete data.profile_pic
        }
        const response = await editParticipant(data, userId)
        if (!response.error) {
            showSuccess("Participant updated successfully")
            setTimeout(() => {
                history.push({
                    pathname: '/participants/list'
                })
            }, 100)
            return true
        } else {
            showError(response.message)
            return false
        }
    }
    return (
        <Layout>
            <Container className="w-50">
                <h2 className="mb-5">Edit Participant</h2>
                {
                    fetched && <ParticipantForm
                        type="Update"
                        isEdit={true}
                        name={user.name || ''}
                        email={user.email || ''}
                        serverUrl={user.profile_image && user.profile_image["path"] || ""}
                        phone_number={user.phone_number}
                        onSubmit={async (formValues) => await formSubmit(formValues)} ></ParticipantForm>
                }


            </Container>
        </Layout>
    )

}
