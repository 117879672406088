import { Dashboard } from '../pages/dashboard'
import { Login } from '../pages/login'
import { Users } from '../pages/users'
import { UsersList } from '../pages/users/list'
import SearchPage from '../pages/search'
import { VideoAdd } from '../pages/video/add'
import { VideoAnalyticsList } from '../pages/video/analytics'
import { VideoList } from '../pages/video'
import { VideoEdit } from '../pages/video/edit'
import { AddCompany } from '../pages/company/add'
import { UserEdit } from '../pages/users/edit'
import { UserAdd } from '../pages/users/add'
import CompanyFeaturedVideos from '../pages/featured-videos/company'
import CategoryFeaturedVideos from '../pages/featured-videos/category'
import Logout from '../pages/logout'
import { PageNotAllowed } from '../pages/pagenotallowed'
import { CompanyList } from '../pages/company'
import { EditCompany } from '../pages/company/edit'
import { VideoModeration } from '../pages/videomoderation'
import { ProfileEdit } from '../pages/profile/edit'
import { VideoModerationEdit } from '../pages/videomoderation/edit'
import { PaticipantsList } from '../pages/participants'
import { ParticipantAdd } from '../pages/participants/add'
import { ParticiapantEdit } from '../pages/participants/edit'
import { AccountActivation } from '../pages/account'

export const routes = [
  {
    path: '/login',
    component: Login,
    isProtected: false,
  },
  {
    path: '/logout',
    component: Logout,
    isProtected: false,
  },
  {
    path: '/pagenotallowed',
    component: PageNotAllowed,
    isProtected: false,
  },
  {
    path: '/dashboard',
    component: Dashboard,
  },
  //User
  {
    path: '/users',
    component: Users,
    routes: [
      {
        path: '/users/list',
        component: UsersList,
      },
      {
        path: '/users/edit/:id',
        component: UserEdit,
      },
      {
        path: '/users/add',
        component: UserAdd,
      },
      {
        path: '/users',
        defaultTo: '/users/list',
      },
    ],
  },

  //Videos
  {
    path: '/search',
    component: SearchPage,
  },
  {
    path: '/videos/list',
    component: VideoList,
  },
  {
    path: '/videos/add',
    component: VideoAdd,
  },
  {
    path: '/videos/edit/:id',
    component: VideoEdit,
  },
  {
    path: '/videos/analytics',
    component: VideoAnalyticsList,
  },
  {
    path: '/videos',
    defaultTo: '/videos/list',
  },

  //Featured Video Category
  {
    path: '/featured-videos/category',
    component: CategoryFeaturedVideos,
  },
  //Featured Video Company
  {
    path: '/featured-videos/company',
    component: CompanyFeaturedVideos,
  },

  //company
  {
    path: '/company/list',
    component: CompanyList,
  },
  {
    path: '/company/add',
    component: AddCompany,
  },
  {
    path: '/company/edit/:id',
    component: EditCompany,
  },
  {
    path: '/company',
    defaultTo: '/company/list',
  },

  // video moderate
  {
    path: '/videomoderation/list',
    component: VideoModeration,
  },
  {
    path: '/videomoderation/edit/:id',
    component: VideoModerationEdit,
  },
  {
    path: '/videomoderation',
    defaultTo: '/videomoderation/list',
  },

  //Participants
  {
    path: '/participants/list',
    component: PaticipantsList,
  },
  {
    path: '/participants/add',
    component: ParticipantAdd,
  },
  {
    path: '/participants/edit/:id',
    component: ParticiapantEdit,
  },
  {
    path: '/participants',
    defaultTo: '/participants/list',
  },
  {
    path: '/profile/edit',
    component: ProfileEdit,
  },

  //Account Activation
  {
    path: '/accountactivation/:token',
    component: AccountActivation,
    isProtected: false,
  },
]
