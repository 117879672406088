import { execute, HTTPMethod } from './http.service'
export const companies = async () => {
  const response = await execute(`protected/company`)
  return response
}
export const getCompanyList = async (queryParam = '') => {
  const query = queryParam != '' ? `${queryParam}` : ''
  const response = await execute(`/protected/company/paginate${query}`)
  return response
}

export const getCompanyById = async (companyId: string) => {
  const response = await execute(`protected/company/${companyId}`)
  return response
}

export const addCompany = async (reqBody: object = {}) => {
  const response = await execute(
    `/protected/company/create/company-user`,
    HTTPMethod.POST,
    reqBody
  )
  return response
}

export const updateCompany = async (reqBody: any) => {
  const response = await execute(
    `protected/company/edit/company-user`,
    HTTPMethod.PUT,
    reqBody
  )
  return response
}

export const updateCompanyAndCreateUser = async (reqBody: any) => {
  const response = await execute(
    `protected/company/edit/company-and-create-user`,
    HTTPMethod.PUT,
    reqBody
  )
  return response
}

export const deleteCompany = async (reqBody: object = {}) => {
  const response = await execute(
    `/protected/company`,
    HTTPMethod.DELETE,
    reqBody
  )
  return response
}
