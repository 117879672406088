import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/layout';


export const PageNotAllowed: FunctionComponent = () => {

    return (
        <Layout>
            <div>
                <h3>
                    Permission denied
                </h3>
            </div>
        </Layout>
    );
}