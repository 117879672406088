import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { UserForm } from "../../../components/forms/user";
import Layout from '../../../components/layout';
import { uploadFile } from '../../../services/file.service';
import { editUser, getUserById } from '../../../services/user.service';
import { showError, showSuccess, toCamel } from '../../../utils/helper';

export const UserEdit: React.FunctionComponent = (props: any) => {
    const history = useHistory()
    const { id: userId } = props.match.params
    let fileId = 0
    const [fetched, setFetched] = useState(false)
    const [company, setCompany] = useState([])

    const [user, setUser] = useState({
        "id": 0,
        "is_active": 0,
        "slug": null,
        "firstName": "",
        "lastName": "",
        "profile": { "path": "" },
        "email": "",
        "role": "",
    })
    useEffect(() => {
        getUser()
    }, [userId])
    const getUser = async () => {
        const response = await getUserById(userId)
        if (!response.error) {
            setUser(response.data)
            setCompany(getCompaniesData(response.data.UserCompanies))
        } else {
            showError(response.message)
        }
        setFetched(true)
    }

    const getCompaniesData = (participants: any) => {
        if (participants.length < 1) {
            return []
        }

        const participantsArray = participants.map((element: any) => {
            return element.company_id
        })

        return participantsArray
    }

    const formSubmit = async (data: any) => {
        if (fileId == 0 && data.profilePic && data.profilePic instanceof File) {
            const formData = new FormData()
            formData.set('file', data.profilePic)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                fileId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }

        }

        if (fileId != 0) {
            data.profilePic = fileId
        } else {
            delete data.profilePic
        }

        if (data.role == "admin") {
            delete data.company_id
            data.role = "admin"
        }

        // if (isManager()) {
        //     data.role = "company_manager"
        //     data.company_id = [JSON.parse(getCookie(USER_STORAGE_KEY)).company_id]
        // } else {
        //     data.role = "admin"
        // }

        delete data.password
        // if(user.email.trim() === data.email.trim()){
        //     delete data.email
        // }
        const response = await editUser(data, userId)
        if (!response.error) {
            showSuccess("User updated successfully")
            setTimeout(() => {
                history.push({
                    pathname: '/users/list'
                })
            }, 100)
            return true
        } else {
            showError(response.message)
            return false
        }
    }
    return (
        <Layout>
            <Container className="w-50">
                <h2 className="mb-5">{`Edit ${toCamel(user.role)}`}</h2>
                {
                    fetched && <UserForm
                        type="Update"
                        isEdit={true}
                        firstName={user.firstName || ''}
                        lastName={user.lastName || ''}
                        email={user.email || ''}
                        role={user.role || ''}
                        isActive={user.is_active || 0}
                        serverUrl={user.profile && user.profile["path"] || ""}
                        company_id={company}
                        onSubmit={async (formValues) => await formSubmit(formValues)} ></UserForm>
                }


            </Container>
        </Layout>
    )

}
