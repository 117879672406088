import { getCookie, removeCookie, setCookie } from '../utils/cookie'
import { execute, HTTPMethod } from './http.service'
export const TOKEN_STORAGE_KEY = 'accessToken'
export const USER_STORAGE_KEY = 'user'
export const login = async (reqBody: any) => {
  const res = await execute('auth/login', HTTPMethod.POST, reqBody)
  if (!res.error) {
    setCookie(TOKEN_STORAGE_KEY, res.data.accessToken)
    const user = res.data
    user['company_id'] =
      res.data.role === 'company_manager'
        ? res.data.UserCompanies && res.data.UserCompanies[0].company_id
        : 0
    setCookie(USER_STORAGE_KEY, user)
    return true
  }
  return false
}
export const isAuthenticated = (): boolean => {
  const token = getCookie(TOKEN_STORAGE_KEY)
  if (token && token != '') {
    return true
  }
  return false
}
export const logout = (): void => {
  removeCookie(TOKEN_STORAGE_KEY)
  removeCookie(USER_STORAGE_KEY)
}
