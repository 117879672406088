import React from 'react'
import { default as ReactSelect } from 'react-select'

type Props = {
  options: Array<object>
  value?: object
  onChange: any
  allowSelectAll?: boolean
  isMulti?: boolean
  isDisabled?: boolean
  closeMenuOnSelect?: boolean
  hideSelectedOptions?: boolean
  placeholder?: string
  className?: string
  isSearchable?: boolean
}

const SelectOption = (props: Props) => {
  const multiCustomStyles = {
    valueContainer: (provided: any) => ({
      ...provided,
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      display: 'block',
    }),
  }

  const allOption = {
    label: 'Select all',
    value: '*',
  }
  if (props.allowSelectAll || props.isMulti) {
    let selectAllOptions = [...props.options]
    if (props.allowSelectAll) {
      selectAllOptions = [allOption, ...selectAllOptions]
    }
    return (
      <ReactSelect
        {...props}
        options={selectAllOptions}

      // styles={multiCustomStyles}
      // onChange={(selected) => {
      //     if (
      //         selected !== null &&
      //         selected.length > 0 &&
      //         selected[selected.length - 1].value === allOption.value
      //     ) {
      //         return props.onChange(props.options)
      //     }
      //     return props.onChange(selected)
      // }}
      />
    )
  }

  return (
    <ReactSelect
      {...props}
      styles={multiCustomStyles}
      className="single-select"
    //   menuIsOpen={true}
    />
  )
}

export default SelectOption
