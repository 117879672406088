import React, { useState, useEffect } from 'react'
import { VideoForm } from '../../../components/forms/video'
import { video } from '../../../services/video.service'
import { updateVideo } from '../../../services/video.service'
import { uploadFile } from '../../../services/file.service'
import Layout from '../../../components/layout'
import { Container } from 'react-bootstrap'
import { getCookie } from '../../../utils/cookie'
import { isManager } from '../../../services/role.service'
import { USER_STORAGE_KEY } from '../../../services/auth.service'
import { showError, showSuccess } from '../../../utils/helper'
import { useHistory } from 'react-router-dom'

export const VideoEdit = (props: any) => {
  const history = useHistory()
  let transcriptId = 0, thumbId = 0;
  const [fetched, setFetched] = useState(false)
  const [formData, setformData] = useState({
    title: '',
    summary: '',
    url: '',
    companyId: 0,
    categoryId: 0,
    isFeatured: false,
    isCompanyFeatured: false,
    transcript: "",
    cover: "",
    videoDuration: ""
  })

  const [participants, setParticipants] = useState([])
  const [tags, setTags] = useState([])

  const { id } = props.match.params

  useEffect(() => {
    async function fetchInitialData() {
      const response = await video(id)
      if (response.error === true) {
        showError(response.message)
        return
      }
      // setVideoData(response.data)
      prepareData(response.data)
    }

    fetchInitialData()
  }, [])

  const prepareData = (data: any) => {
    setformData({
      title: data.title,
      summary: data.summary,
      url: data.url,
      companyId: data.company_id,
      categoryId: data.category_id,
      isFeatured: data.is_featured,
      isCompanyFeatured: data.is_company_featured,
      transcript: data.transcript && data.transcript.path,
      cover: data.cover && data.cover.path,
      videoDuration: data.video_length
    })

    setParticipants(getParticipantsData(data.VideoParticipants))
    setTags(getTags(data.VideoTags))

    setFetched(true)
  }

  const getParticipantsData = (participants: any) => {
    if (participants.length < 1) {
      return []
    }

    const participantsArray = participants.map((element: any) => {
      return element.participant_id
    })

    return participantsArray
  }

  const getTags = (tags: any) => {
    if (tags.length < 1) {
      return []
    }

    const tagsArray = tags.map((element: any) => {
      return element.tag_id
    })

    return tagsArray
  }

  const onFormSubmit = async (values: any) => {
    if (transcriptId == 0 && values.transcript_path && values.transcript_path instanceof File) {
      const formData = new FormData()
      formData.set('file', values.transcript_path)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        transcriptId = fileResponse.data.data.id

      } else {
        showError("File upload failed")
        return false
      }

    }

    if (thumbId == 0 && values.cover_image && values.cover_image instanceof File) {
      const formData = new FormData()
      formData.set('file', values.cover_image)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        thumbId = fileResponse.data.data.id

      } else {
        showError("File upload failed")
        return false
      }

    }
    if (transcriptId != 0) {
      values.transcript_path = transcriptId
    } else {
      delete values.transcript_path
    }

    if (thumbId != 0) {
      values.cover_image = thumbId
    } else {
      delete values.cover_image
    }
    if (isManager()) {
      values.company_id = JSON.parse(getCookie(USER_STORAGE_KEY)).company_id
    }
    const { id } = props.match.params
    const response = await updateVideo(id, values)
    if (!response.error) {
      showSuccess("Video updated successfully")
      transcriptId = 0
      thumbId = 0
      setTimeout(() => {
        history.push({
          pathname: '/videos/list'
        })
      }, 100)
      return true
    } else {
      showError(response.message)
      return false
    }
  }

  return (
    < Layout >
      <Container className="w-50 mx-auto">
        <h2 className="mb-5">Edit Video</h2>
        {fetched && (
          <VideoForm
            isEdit={true}
            onSubmit={(values) => onFormSubmit(values)}
            type="Update"
            title={formData.title}
            summary={formData.summary}
            url={formData.url}
            company_id={formData.companyId}
            category_id={formData.categoryId}
            participants={participants}
            tags={tags}
            // is_featured={formData.isFeatured}
            // is_company_featured={formData.isCompanyFeatured}
            transcriptUrl={formData.transcript || ""}
            coverUrl={formData.cover || ""}
            video_length={formData.videoDuration}
          />
        )}
      </Container>
    </Layout >
  )
}
