import React, { FunctionComponent, useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import {
  activeVideos,
  videoSuggestions,
  makeFeaturedVideos,
  setHomepageFeaturedVideos,
  getHomepageFeaturedVideos,
} from '../../../services/video.service'
import { showError, showSuccess } from '../../../utils/helper'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { TableWithFPS } from '../../../components/common/tablewithfps'
import AutoComplete from '../../../components/autocomplete'

const CategoryFeaturedVideos: FunctionComponent = () => {
  const [featuredVideos, setFeaturedVideos] = useState<any>({})
  const [featuredVideosFetched, setFeaturedVideosFetched] = useState(false)
  const [searchedVideos, setSearchedVideos] = useState<any>({})
  const [homepageFeaturedVideos, setHomepageFeaturedVideosState] = useState('')

  useEffect(() => {
    async function fetchInitialData() {
      await fetchFeaturedVideos()
      await fetchHomepageFeaturedVideos()
    }

    fetchInitialData()
  }, [])

  const fetchFeaturedVideos = async () => {
    const response = await activeVideos('is_featured=1')
    if (response.error === true) {
      showError(response.message)
      return false
    }

    setFeaturedVideos(response.data)
    setFeaturedVideosFetched(true)
  }

  const fetchHomepageFeaturedVideos = async () => {
    const response = await getHomepageFeaturedVideos()
    if (response.error === true) {
      showError(response.message)
      return false
    }

    setHomepageFeaturedVideosState(response.data.value)
    setFeaturedVideosFetched(true)
  }

  const onHomepageFeaturedVideosClick = async () => {
    const response = await setHomepageFeaturedVideos({
      videoIds: homepageFeaturedVideos,
    })

    if (response.error === true) {
      showError(response.message)
      return false
    } else {
      showSuccess("Video added to Homepage Featured list successfully")
    }
  }

  const onVideoSelection = async (id: number) => {
    const body = {
      key: 'is_featured',
      isFeatured: true,
      videoIds: [id],
    }

    const response = await makeFeaturedVideos(body)

    if (response.error === true) {
      showError(response.message)
      return false
    } else {
      showSuccess("Video added to Category Featured list successfully")
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    // await fetchFeaturedVideos()
    // setSearchedVideos([])
  }

  const createColumnHeaders = () => {
    return [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Title',
        selector: 'title',
        sortable: true,
        grow: 400,
        wrap: true,
        // cell: (row: any) => {
        //   return truncate(row.title, 30)
        // },
      },
      {
        name: 'Company',
        selector: 'company.name',
        sortable: true,
        grow: 100,
        wrap: true,
      },
      {
        name: 'Category',
        selector: 'category.name',
        grow: 50,
        wrap: true,
      },
    ]
  }

  return (
    <Layout>
      <Container fluid className="company-featured-videos-container">
        <Row>
          <Col xs={12} md={5}>
            <Row className="form-group">
              <Col xs={12} md={9}>
                <label>Homepage featured videos</label>
                <input
                  type="text"
                  onChange={(e) =>
                    setHomepageFeaturedVideosState(e.target.value)
                  }
                  value={homepageFeaturedVideos}
                  className="form-control"
                />
              </Col>
              <Col xs={12} md={3}>
                <Button
                  variant="primary"
                  className=""
                  onClick={() => onHomepageFeaturedVideosClick()}
                >
                  Set
                </Button>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={1}></Col>
          {/* <Col xs={12} md={4}>
            <AutoComplete
              label="Search Videos"
              searchFunction={videoSuggestions}
              selectedValue={(selected: any) =>
                onVideoSelection(selected.value)
              }
            />
          </Col> */}
        </Row>
        <div className="d-flex justify-content-between">
          <h2 className="page-title mb-3">Category Featured Videos</h2>
          <div></div>
        </div>
      </Container>
      <Container fluid className="category-featured-videos-container mb-10">
        {featuredVideosFetched && (
          <Container fluid>
            <TableWithFPS
              // title={"Category Featured Videos"}
              loading={!featuredVideosFetched}
              columns={createColumnHeaders()}
              data={featuredVideos}
              defaultSortField="id"
              paginationPerPage={10}
              paginationTotalRows={featuredVideos.length}
              pagination={false}
              sortServer={false}
              subHeaderComponent={
                <Row>
                  <Col style={{ width: 500 }}>
                    <AutoComplete
                      label="Search Videos"
                      searchFunction={videoSuggestions}
                      selectedValue={(selected: any) =>
                        onVideoSelection(selected.value)
                      }
                    />
                  </Col>
                </Row>
              }
            />
          </Container>
        )}
      </Container >
    </Layout >
  )
}

export default CategoryFeaturedVideos
