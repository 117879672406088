import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'react-bootstrap'
interface Props {
    show: boolean
    bodyText: string
    showNegative?: boolean
    negativeText?: string
    positiveText: string
    onNegative?: () => void
    onPositive: () => void
}
export const AlertModal = (props: Props) => {
    const { show, bodyText, showNegative, negativeText, positiveText, onNegative, onPositive } = props
    const [showModal, setShowModal] = useState(show)
    useEffect(() => {
        setShowModal(show)
    }, [show])
    return (
        <Modal
            {...props}
            size="sm"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={showModal}
        >
            <Modal.Body>
                <p>{bodyText}</p>
            </Modal.Body>
            <Modal.Footer>
                {
                    showNegative &&
                    <Button variant="secondary" onClick={() => {
                        setShowModal(false)

                        onNegative &&
                            onNegative()
                    }
                    }>
                        {negativeText}
                    </Button>
                }

                <Button variant="primary" onClick={() => {
                    setShowModal(false)
                    onPositive()
                }}>
                    {positiveText}
                </Button>
            </Modal.Footer>
        </Modal >
    )
} 