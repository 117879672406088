import React from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { UserForm } from "../../../components/forms/user";
import Layout from '../../../components/layout';
import { uploadFile } from '../../../services/file.service';
import { addUser } from '../../../services/user.service';
import { showError, showSuccess } from '../../../utils/helper';

export const UserAdd: React.FunctionComponent = () => {
      let fileId = 0
      const history = useHistory()
      const formSubmit = async (data: any) => {
            if (fileId == 0 && data.profilePic && data.profilePic instanceof File) {
                  const formData = new FormData()
                  formData.set('file', data.profilePic)
                  const fileResponse = await uploadFile(formData)
                  if (!fileResponse.error) {
                        fileId = fileResponse.data.data.id

                  } else {
                        showError("File upload failed")
                        return false
                  }

            }

            if (fileId != 0) {
                  data.profilePic = fileId
            }
            data.role = "admin"
            // if (isManager()) {
            //       data.role = "company_manager"
            //       data.company_id = [JSON.parse(getCookie(USER_STORAGE_KEY)).company_id]
            // }
            const response = await addUser(data)
            if (!response.error) {
                  showSuccess("User added successfully")
                  fileId = 0
                  setTimeout(() => {
                        history.push({
                              pathname: '/users/list'
                        })
                  }, 100)
                  return true
            } else {
                  showError(response.message)
                  return false
            }

      }
      return (
            <Layout>
                  <Container className="w-50 mr-auto container" >
                        <h2 className="mb-5">Add Admin User</h2>
                        {
                              <UserForm
                                    onSubmit={async (formValues) => await formSubmit(formValues)} type="Add"></UserForm>
                        }


                  </Container>
            </Layout >
      )

}
