import React from 'react'
import { Col, Row } from 'react-bootstrap'
import AsyncSelect from 'react-select/async'

interface Props {
  searchFunction: (searchTerm: string) => Promise<any[]>
  label: string
  selectedValue: any
}

const AutoComplete = (props: Props) => {
  const { searchFunction, label, selectedValue } = props

  const filterData = async (inputValue: string) => {
    const searchedValues = await searchFunction(inputValue)
    return searchedValues
  }

  const promiseOptions = (inputValue: string) =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(filterData(inputValue))
      }, 1000)
    })

  return (
    <div className="autocomplete form-group ">
      <label className="col-form-label text-right">{label}</label>
      <Row>
        <Col>
          <AsyncSelect
            cacheOptions
            defaultOptions
            loadOptions={promiseOptions}
            onChange={selectedValue}
          />
        </Col>
      </Row>
    </div>
  )
}

export default AutoComplete
