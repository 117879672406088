import React, { FunctionComponent } from 'react'
import VideoSearchResults from '../../components/algoliasearch/videosearchresults'
import queryString from 'query-string'
import Layout from '../../components/layout'

type Props = {
  location: {
    search: any
  }
}

const SearchPage: FunctionComponent<Props>  = (props: Props) => {
  const values = queryString.parse(props.location.search)
  return (
    <Layout>
      {queryString && <VideoSearchResults query={values.s} />}
    </Layout>
  )
}

export default SearchPage
