import { getFormattedOptionsData } from '../utils/helper'
import { execute, HTTPMethod } from './http.service'

export const videos = async (queryParam = '') => {
  const query = queryParam != '' ? `${queryParam}` : ''
  const response = await execute(`/protected/videos/paginate${query}`)
  return response
}

export const video = async (id: number) => {
  const response = await execute(`protected/videos/${id}`)
  return response
}

export const addVideo = async (videoBody: any) => {
  const response = await execute(`protected/videos`, HTTPMethod.POST, videoBody)
  return response
}

export const updateVideo = async (id: number, videoBody: any) => {
  const response = await execute(
    `protected/videos/${id}`,
    HTTPMethod.PUT,
    videoBody
  )
  return response
}

export const deleteVideo = async (reqBody: object = {}) => {
  const response = await execute(
    `/protected/videos`,
    HTTPMethod.DELETE,
    reqBody
  )
  return response
}

export const activeVideos = async (queryParam = '') => {
  const query = queryParam != '' ? `${queryParam}` : ''
  const response = await execute(`protected/videos?${query}`)
  return response
}

export const videoSuggestions = async (searchTerm: any) => {
  if (searchTerm == '') {
    return []
  }
  const response = await execute(
    `protected/videos/get-video-suggestions`,
    HTTPMethod.POST,
    { string: searchTerm }
  )
  if (response.error) {
    return []
  }
  return getFormattedOptionsData(response.data, 'title')
}

export const makeFeaturedVideos = async (videoBody: any) => {
  const response = await execute(
    `protected/videos/handle-featured-state`,
    HTTPMethod.POST,
    videoBody
  )
  return response
}

export const setHomepageFeaturedVideos = async (videoBody: any) => {
  const response = await execute(
    `protected/videos/save-homepage-featured-videos`,
    HTTPMethod.POST,
    videoBody
  )
  return response
}

export const getHomepageFeaturedVideos = async () => {
  const response = await execute(
    `protected/videos/get-homepage-featured-videos`
  )
  return response
}

export const getModerateVideos = async (queryParam = '') => {
  const response = await execute(
    `protected/videos/moderation/list${queryParam}`
  )
  return response
}
export const changeModerateState = async (videoBody: any) => {
  const response = await execute(
    `protected/videos/moderation/change-state`,
    HTTPMethod.POST,
    videoBody
  )
  return response
}
export const getVideosByIds = async (ids: any) => {
  const response = await execute(
    `videos/algolia`,
    HTTPMethod.POST,
    {ids}
  )
  return response
}
