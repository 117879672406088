import React from 'react';
import { execute, HTTPMethod } from '../../services/http.service';
import { UserForm } from "../../components/forms/user";
import { Link, Route, Switch } from 'react-router-dom';
import { RouteWithSubRoutes } from '../../components/common/router-with-sub-routes';

export const Users = (props: any) => {
      const { routes } = props;



      return (<div>

            {/* <span className="input-group-btn">
            <Link to="/users/1" >user</Link>
            </span> */}
            <Switch>
                  {
                        routes.map((route: any, i: number) => (
                              <RouteWithSubRoutes key={i} {...route} />
                        ))
                  }
            </Switch>
            {/* button component for add : use userform*/}
            {/* table */}
            {/* in action, there will be edit : use userform*/}
      </div>
      )

}
