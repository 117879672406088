import React from 'react'
import { isAuthenticated } from '../../services/auth.service'
import { Redirect, Route } from 'react-router-dom'
import { isAdmin, isManager, isRestrictedRoute } from '../../services/role.service'

export const RouteWithSubRoutes = (route: any) => {
  if (route.isProtected === false) {
    return (
      <Route
        path={route.path}
        render={(props: any) => (
          // pass the sub-routes down to keep nesting
          <route.component {...props} routes={route.routes} />
        )}
      />
    )
  }
  return (
    <Route
      path={route.path}
      render={(props: any) => {
        if (route.defaultTo) {
          return <Redirect exact from={route.path} to={route.defaultTo} />
        }
        return isAuthenticated() ? (
          isAdmin() ?
            < route.component {...props} routes={route.routes} />
            :
            isManager() && !isRestrictedRoute(route.path) ?
              < route.component {...props} routes={route.routes} />
              :
              <Redirect
                to={{
                  pathname: '/pagenotallowed',
                }}
              />
        ) : (
            <Redirect
              to={{
                pathname: '/login',
                state: { from: props.location },
              }}
            />
          )
      }
      }
    />
  )
}
