import React, { useEffect, useState } from 'react'
import { VideoForm } from '../../../components/forms/video'
import { addVideo } from '../../../services/video.service'
import { uploadFile } from '../../../services/file.service'
import Layout from '../../../components/layout'
import { Container } from 'react-bootstrap'
import { isManager } from '../../../services/role.service'
import { getCookie } from '../../../utils/cookie'
import { USER_STORAGE_KEY } from '../../../services/auth.service'
import { showError, showSuccess } from '../../../utils/helper'
import { useHistory } from 'react-router-dom'

export const VideoAdd: React.FunctionComponent = (props: any) => {
  const history = useHistory()
  let transcriptId = 0, thumbId = 0;
  const onFormSubmit = async (values: any) => {
    if (transcriptId == 0 && values.transcript_path && values.transcript_path instanceof File) {
      const formData = new FormData()
      formData.set('file', values.transcript_path)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        transcriptId = fileResponse.data.data.id

      } else {
        showError("File upload failed")
        return false
      }

    }

    if (thumbId == 0 && values.cover_image && values.cover_image instanceof File) {
      const formData = new FormData()
      formData.set('file', values.cover_image)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        thumbId = fileResponse.data.data.id

      } else {
        showError("File upload failed")
        return false
      }

    }

    if (transcriptId != 0) {
      values.transcript_path = transcriptId
    }

    if (thumbId != 0) {
      values.cover_image = thumbId
    }

    if (isManager()) {
      values.company_id = JSON.parse(getCookie(USER_STORAGE_KEY)).company_id
    }

    const response = await addVideo(values)
    if (!response.error) {
      showSuccess("Video added successfully")
      transcriptId = 0
      thumbId = 0
      setTimeout(() => {
        history.push({
          pathname: '/videos/list'
        })
      }, 100)
      return true
    } else {
      showError(response.message)
      return false
    }
  }

  return (
    <Layout>
      <Container className="w-50 mr-auto">
        <h2 className="mb-5">Add a Video</h2>
        <VideoForm onSubmit={async (values) => await onFormSubmit(values)} type="Add" />
      </Container>
    </Layout>
  )
}
