import React, { useEffect, useState } from 'react'
import { MultiSelect } from '../common/multi-select'
import {
  participants,
  addParticipant,
} from '../../services/participant.service'
import { ErrorMessage, Field } from 'formik'
import { showError } from '../../utils/helper'

type TOptions = {
  value: any
  label: any
}

interface Props {
  onChange: (value: any) => void
  name: string
  selectedOptions?: Array<number>
}

const Participants = (props: Props) => {
  const [options, setOptions] = useState<TOptions[]>()
  const [selected, setSelected] = useState([])
  const { onChange, name, selectedOptions } = props
  let selectedOptionIds: any[] = []

  useEffect(() => {
    async function fetchInitialData() {
      const response = await participants()
      if (response.error === true) {
        showError(response.message)
        return
      }
      createOptions(response.data)
    }

    fetchInitialData()
  }, [])

  useEffect(() => {
    createSelectedOptions(options, selectedOptions)
  }, [selectedOptions])

  const createOptions = (data: Array<unknown>) => {
    const defaultOptions = data.map((element: any) => {
      return {
        value: element.id,
        label: element.name,
      }
    })

    setOptions(defaultOptions)

    if (selectedOptionIds != undefined) {
      createSelectedOptions(defaultOptions, selectedOptions)
    }
  }

  const onFormSubmit = (values: any) => {
    let selectedValues = []
    if (values) {
      selectedValues = values.map((element: any) => {
        return element.value
      })
    }
    onChange(selectedValues)
    setSelected(values)
  }

  const handleCreate = async (value: any) => {
    const newParticipant = {
      name: value,
    }

    const createResponse = await addParticipant(newParticipant)
    if (createResponse.error === true) {
      showError(createResponse.message)
      return
    }

    const response = await participants()
    if (response.error === true) {
      showError(response.message)
      return
    }
    if (!createResponse.error) {
      const ids: any = selected
        .filter((sec: any) => sec.value)
        .map((i: any) => i.value)
      selectedOptionIds = ids.concat(createResponse.data.id)
    }
    createOptions(response.data)
  }

  const createSelectedOptions = (
    defaultOptions: any,
    selectedOptions: Array<number> = []
  ) => {
    if (defaultOptions) {
      const newArray = selectedOptions.concat(selectedOptionIds)
      const selected = defaultOptions.filter((option: any) => {
        return newArray.indexOf(option.value) !== -1
      })
      setSelected(selected)
      const ids: any = selected
        .filter((sec: any) => sec.value)
        .map((i: any) => i.value)
      onChange(ids)
    }
  }

  return (
    // <Field name={name}>
    <div className="participants-container">
      <label>Participants</label>
      <MultiSelect
        isMultiSelect={true}
        options={options}
        value={selected}
        onCreate={(value) => handleCreate(value)}
        onChange={(value) => onFormSubmit(value)}
        creatable={true}
      />
      <ErrorMessage name={name} />
    </div>
    // </Field>
  )
}

export default Participants
