import React, { FunctionComponent, useEffect, useState } from 'react'
import { deleteVideo, videos } from '../../services/video.service'
import { TableWithFPS } from '../../components/common/tablewithfps'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  getCoverImage,
  getQueryParams,
  getServerUrl,
  kFormatter,
  showError,
  showSuccess,
  utcToLocalDateTime,
} from '../../utils/helper'
import { Button, Container } from 'react-bootstrap'
import { AlertModal } from '../../components/common/alertmodal'
import {CopyToClipboard} from 'react-copy-to-clipboard';

export const VideoList: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [videoData, setVideos] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({ ...queryParams })
  const [filterOpt, setFilterOpt] = useState({})
  const filterOptions = [{ name: "Title", id: "title" }, { name: "Company", id: "company" }]
  const [showModal, setShowModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteRow, setDeleteRow] = useState([])

  const WEB_CLIENT_URL = (() => process.env.REACT_APP_WEB_CLIENT_URL || '')()

  useEffect(() => {
    const queryString = getQueryParams(query)
    history.push({
      pathname: '/videos/list',
      search: queryString,
    })
  }, [query])

  useEffect(() => {
    if (query.filterBy) {
      const defaultOption = filterOptions.filter((item) => item.id === query.filterBy)
      if (defaultOption.length > 0) {
        setFilterOpt({
          column: defaultOption,
          filterText: query.filterValue
        })
      }
    }
    fetchVideos(location.search || '')
  }, [location])

  const fetchVideos = async (queryParams: string) => {
    if (loading) return
    setLoading(true)
    const videosData = await videos(queryParams)
    if (!videoData.error) {
      setVideos(videosData)
    }
    setLoading(false)
  }

  const handleOnCopy = () => {
    showSuccess("Copied")
  }

  const getEmbedCode = (slug: any)=> {
    const linkUrl = `"${WEB_CLIENT_URL}/embed/${slug}"`
    return `<iframe 
        width="1280" 
        height="670" 
        src=${linkUrl}
        frameborder="0" 
        allowfullscreen>
    </iframe>`
  }

  const createColumnHeaders = () => {
    return [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Title',
        selector: 'title',
        sortable: true,
        grow: 200,
        wrap: true,
        cell: (row: any) => {
          return (
            <div className="d-flex align-items-center">
              <div className="video-thumb">
                <div className="time-stamp">{row.video_length}</div>
                <img className="ml-1 video-img"  src={
                    row.cover
                      ? getServerUrl(row.cover.path)
                      : getCoverImage(row.url)
                  }  />
              </div>
              <div>{row.title}</div>
            </div>
          )
        },
      },
      {
        name: 'Company',
        selector: 'company.name',
        sortable: true,
        grow: 100,
        wrap: true,
        center: true,
      },
      {
        name: 'Category',
        selector: 'category.name',
        sortable: true,
        grow: 80,
        center: true,
      },
      {
        name: 'Featured',
        selector: 'is_featured',
        sortable: true,
        grow: 100,
        center: true,
        cell: (row: any) => {
          return row.is_featured && row.is_company_featured
            ?'Yes':'No'
        },
      },
      {
        name: 'Action',
        button: true,
        cell: (row: any) => (
          <div style={{ display: "flex", flexDirection: "row" }}>
            <div className="text-primary cursor text-600">
              <CopyToClipboard text={getEmbedCode(row.slug??row.id)}
              onCopy={handleOnCopy}>
              <img className="ml-1" style={{ width: 32, height: 32 }} src={require("../../assets/icon_clipboard.png")} />
            </CopyToClipboard>
            </div>
            <div
              className="text-primary cursor text-600"
              onClick={() => {
                window.location.href = `edit/${row.id}`
              }}
            >
              <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_edit.svg")} />
            </div>
            <div
              className="text-primary cursor text-600 ml-2"
              onClick={() => {
                const obj: any = [row.id]
                setDeleteRow(obj)
                setShowModal(true)
              }}>
              <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_delete.svg")} />
            </div>
          </div>
        ),
      },
    ]
  }

  const onDeleteUser = async () => {
    let deleteObj = selectedRows
    if (deleteRow.length > 0) {
      deleteObj = deleteRow
    }
    const res = await deleteVideo({
      ids: deleteObj
    })
    if (!res.error) {
      showSuccess("Video deleted successfully")
      setSelectedRows([])
      setDeleteRow([])
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      showError(res.message || "Something went wrong")
    }
  }

  return (
    <Layout>
      {
        <Container fluid>
          <div className="d-flex justify-content-between">
            <div className="custom-tab"> 
              <div className="custom-tab--item">
                <h2 className="custom-tab--text custom-tab--link" >Videos</h2>
              </div>
              <div className="custom-tab--item">
                <Link to="/videos/analytics" className="">
                  <h2 className="custom-tab--text">Analytics</h2>
                </Link>
              </div>
            </div>
           
            <div>
              <span className="input-group-btn float-right mr-3 mb-3">
                <Button style={{ width: 100 }} variant="danger" disabled={selectedRows.length <= 0} type="submit" className="" onClick={() => {
                  setShowModal(true)
                }}>Delete</Button>
              </span>

              <span className="input-group-btn float-right mr-3 mb-3">
                <Link style={{ width: 100 }} to="/videos/add" className="btn btn-primary btn-add">
                  Add
              </Link>
              </span>
            </div>
          </div>
        </Container>
      }
      {
        <Container fluid>
          <TableWithFPS
            // title={"Videos"}
            loading={loading}
            columns={createColumnHeaders()}
            data={videoData.data}
            isFilterable={true}
            filterOptions={filterOptions}
            onSort={(columnName: any, sortDirection: string) => {
              const sortObj = {
                sortBy: columnName,
                order: sortDirection,
                page: '1',
              }
              setQuery({ ...query, ...sortObj })
            }}
            onFilter={(filterColumn: string, filterText: string) => {
              if (filterText != "") {
                const filterObj = {
                  filterBy: filterColumn,
                  filterValue: filterText,
                  page: '1'
                }
                setQuery({ ...query, ...filterObj })
              } else {
                const filterObj = {
                  page: '1'
                }
                delete query.filterBy
                delete query.filterValue
                setQuery({ ...query, ...filterObj })
              }

            }}
            defaultSortField={query.sortBy && query.sortBy.toString() || "id"}
            defaultSortAsc={query.order && query.order == "asc" || false}
            paginationPerPage={videoData.limit}
            paginationTotalRows={videoData.total}
            onPageChange={(page: number) => {
              setQuery({ ...query, page: `${page}` })
            }}
            filterOpt={filterOpt}
            paginationDefaultPage={videoData.currentPage}
            selectableRows={true}
            onSelectedRowsChange={(selectedRows: any) => { setSelectedRows(selectedRows) }}
          />
          <AlertModal
            show={showModal}
            bodyText={"Are you sure?"}
            positiveText={"Yes"}
            onPositive={async () => {
              await onDeleteUser()
              setShowModal(false)
            }
            }
            showNegative
            negativeText={"No"}
            onNegative={() => {
              setDeleteRow([])
              setShowModal(false)
            }}
          />
        </Container>
      }
    </Layout>
  )
}
