import React, { ReactElement } from 'react'

class Loader extends React.Component {
    render(): ReactElement {
        return (
            <div className="loader">
                <span className="data-loader">
                    <div className={`spinner-border`} />
                </span>
            </div>
        )
    }
}

export default Loader
