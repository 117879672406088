import React from 'react'
import { useField, ErrorMessage, Field } from 'formik'
import { Form } from 'react-bootstrap'
import MaskedInput from 'react-text-mask'
import TextareaAutosize from 'react-autosize-textarea'

export const InputTextField = (props: any) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <Form.Group controlId={props.name}>
            <Form.Label>{props.label}</Form.Label>
            <Form.Control {...field} {...props} />
            <div className="text-danger"><ErrorMessage name={props.name} /></div>
          </Form.Group>
        )}
      </Field>
    </>
  )
}

export const MultiTextField = (props: any) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <Form.Group controlId={props.name}>
            <Form.Label>{props.label}</Form.Label>
            <TextareaAutosize
              {...field} {...props}
              rows={3}
              className="form-control"
            />
            <div className="text-danger"><ErrorMessage name={props.name} /></div>

          </Form.Group>
        )}
      </Field>
    </>
  )
}

export const MaskedInputTextField = (props: any) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <Form.Group controlId={props.name}>
            <Form.Label>{props.label}</Form.Label>
            <MaskedInput
              {...field} {...props}
              mask={props.mask}
              className="form-control"
              guide={true}
              id="my-input-id"
            // onBlur={() => { }}
            // onChange={() => { }}
            />
            <div className="text-danger"><ErrorMessage name={props.name} /></div>
          </Form.Group>
        )}
      </Field>
    </>
  )
}

export const Dropdown = (props: any) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <Form.Group controlId={props.name}>
            <Form.Label>{props.labelname}</Form.Label>
            <Form.Control as="select" {...field} {...props}>
              {props.options.map((option: any, index: number) => {
                return (
                  <option key={index} value={option.value}>
                    {option.name}
                  </option>
                )
              })}
            </Form.Control>
            <div className="text-danger"><ErrorMessage name={props.name} /></div>
          </Form.Group>
        )}
      </Field>
    </>
  )
}

export const Checkbox = (props: any) => {
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <Form.Group controlId={props.name}>
            <Form.Check
              type="checkbox"
              label={props.labelname}
              // checked={props.checked}
              // onChange={props.onChange}
              {...field}
              {...props}
            />
          </Form.Group>
        )}
      </Field>
      <div className="text-danger"><ErrorMessage name={props.name} />  </div>

    </>
  )
}

// export const Button = (props: any) => {
//   const [field, meta, helpers] = useField(props)
//   return (
//     <>
//       <BootstrapButton {...field} {...props}>
//         {props.label}
//       </BootstrapButton>
//       <ErrorMessage name={props.name} />
//     </>
//   )
// }

export const FileUpload = (props: any) => {
  const [field, meta, helpers] = useField(props)
  return (
    <>
      <Field name={props.name}>
        {({ field, formProps }: { field: any; formProps: any }) => (
          <div>
            <label htmlFor="file">File upload</label>
            <input
              id="file"
              name={props.name}
              type="file"
              {...field}
              {...props}
              onChange={(event) => {
                props.handleChange(
                  event &&
                  event.currentTarget &&
                  event.currentTarget.files &&
                  event.currentTarget.files[0]
                )
              }}
              className="form-control"
            />
          </div>
        )}
      </Field>
      <div className="text-danger"><ErrorMessage name={props.name} /></div>
    </>
  )
}
