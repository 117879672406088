import moment, { MomentInput } from 'moment'
import { toast } from 'react-toastify'
import { baseUrl } from '../services/http.service'

export const getServerUrl = (filePath: string): string => {
  return `${baseUrl}/api/v1/file/download/${filePath}`
}
export function isValidHttpUrl(string:string): boolean {
  let url
  try {
    url = new URL(string)
  } catch (_) {
    return false
  }
  return url.protocol === 'http:' || url.protocol === 'https:'
}

export function getCoverImage(url: string): string {
  let thumb_url = ''
  if (isValidHttpUrl(url) && url.indexOf('?v=') != -1) {
    const vid = url.split('?v=')[1]
    thumb_url = `https://img.youtube.com/vi/${vid}/hqdefault.jpg`
  }
  return thumb_url
}
export const truncate = (str: string, n: number): string => {
  return str ? (str.length > n ? str.substr(0, n - 1) + '...' : str) : ''
}
export const getQueryParams = (query: any): string => {
  return Object.keys(query)
    .map((key) => key + '=' + query[key])
    .join('&')
}
export const utcToLocalDateTime = (
  utcTime: MomentInput,
  format = 'DD MMM yyyy'
): string => {
  try {
    const utc_timeZ = moment.utc(utcTime).format()
    const localTime = moment.utc(utc_timeZ).toDate()
    // console.log(moment(localTime).format('DD MMM yyyy'))
    const formatString = moment(localTime).format(format)
    return formatString == 'Invalid date' ? '-' : formatString
  } catch (e) {
    return '-'
  }
}

export const toCamel = (s: string): string => {
  const result = s.replace(/([-_][a-z])/gi, ($1) => {
    return $1.toUpperCase().replace('-', ' ').replace('_', ' ')
  })
  const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
  return finalResult
  // return s.replace(/([-_][a-z])/gi, ($1) => {
  //   return $1.toUpperCase().replace('-', ' ').replace('_', ' ')
  // })
}
export const camelCase = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}
export const getFormattedOptionsData = (
  data: any,
  labelKey = 'name',
  valueKey = 'id'
) => {
  return data.map((item: any) => {
    return {
      value: item[valueKey],
      label: camelCase(item[labelKey]),
      color:
        item.is_active != undefined
          ? item.is_active == 1
            ? ''
            : '#ff5665'
          : '',
    }
  })
}
export const showError = (message: string): void => {
  toast.error(message, {
    hideProgressBar: true,
    position: 'bottom-right',
    autoClose: 2000,
  })
}
export const showSuccess = (message: string): void => {
  toast.success(message, {
    hideProgressBar: true,
    position: 'bottom-right',
    autoClose: 2000,
  })
}
export function kFormatter(num: number): number | string {
  if (Math.abs(num) > 999) {
    const t = Math.abs(num) / 1000
    const tFixed: any = t.toFixed(1)
    return Math.sign(num) * tFixed + 'k'
  }
  return Math.sign(num) * Math.abs(num)
}
