import { Form, Formik } from 'formik';
import React, { ReactElement, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import * as Yup from 'yup'
import { isAdmin } from '../../../services/role.service';
import FileUploader from '../../common/fileuploader';
import Loader from '../../loader';
import { InputTextField, MultiTextField } from '../common';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import { EditorState, convertToRaw, ContentState, convertFromHTML } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { valueFocusAriaMessage } from 'react-select/src/accessibility';
interface ICompany {
  onSubmit: (values: any) => Promise<boolean>
  name?: string
  description?: string
  profile_image?: File
  profileUrl?: string
  header_image?: File
  mobile_header_image?: File
  headerUrl?: string
  mobileHeaderImage?: string
  email?: string
  phone?: string
  website?: string
  userEmail?: string;
  firstName?: string;
  lastName?: string;
  type: string
  isEdit?: boolean
}

const CompanySchema = Yup.object().shape({
  name: Yup.string().required('Company title is required'),
  description: Yup.string(),
  profile_image: Yup.mixed(),
  header_image: Yup.mixed(),
  mobile_header_image: Yup.mixed(),
  email: Yup.string().email('Invalid email address'),
  phone: Yup.string().matches(/^[0-9]*$/, 'Enter valid phone number'),
  website: Yup.string()
    // .matches(
    //   /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //   'Enter correct url!'
    // )
    .url('Enter correct url'),
  userEmail: Yup.string().email('Invalid email address').required('User Email is required'),
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
})

export const CompanyForm = (props: ICompany): ReactElement => {
  const onEditorStateChange=(editorState: any, setFieldValue: any)=> {
   //console.log("editorState====", draftToHtml(convertToRaw(editorState.getCurrentContent())))
   setFieldValue('description', draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  const { name, description, profile_image, profileUrl, header_image, headerUrl, mobile_header_image, mobileHeaderImage, email, phone, website, userEmail, firstName, lastName, type, onSubmit, isEdit } = props
  // const [editorState, setEditorState] = useState(convertFromHTML('<p>My initial content.</p>'))
  //const contentState = ContentState.createFromBlockArray(editorState)
  const blocksFromHtml = convertFromHTML(description?description:'');
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
  const editorState = EditorState.createWithContent(contentState);

  return (
    <Formik
      initialValues={{
        name: name || '',
        description: description || '',
        profile_image: profile_image || null,
        header_image: header_image || null,
        mobile_header_image: mobile_header_image || null,
        email: email || '',
        phone: phone || "",
        website: website || "",
        userEmail: userEmail || "",
        firstName: firstName || "",
        lastName: lastName || ""
      }}
      validationSchema={CompanySchema}
      onSubmit={async (values, actions) => {
        try {
          const valid = await CompanySchema.isValid(values)
          if (valid) {
            const res = await onSubmit(values)
            actions.setSubmitting(false)
            if (res && !isEdit) {
              actions.resetForm({
                values: {
                  name: '',
                  description: '',
                  profile_image: null,
                  header_image: null,
                  mobile_header_image: null,
                  email: '',
                  phone: "",
                  website: "",
                  userEmail: "",
                  firstName: "",
                  lastName: ""
                },
              })
            }

          }
        } catch (error) {
          console.log(error)
        }
      }}
    >
      {({
        setFieldValue,
        isSubmitting,
      }) => (
          <div className="video-form-container mb-3">
            <Form>
              <h4>Company Details</h4>
              <div>
                <InputTextField label="Company Name" name="name" placeholder="Company Name" />
              </div>
              <div className="form-textarea">
                <Editor
                  wrapperClassName="wrapper-class"
                  editorClassName="editor-class"
                  stripPastedStyles={true}
                  defaultEditorState={editorState}
                  toolbar={{
                    inline: { inDropdown: true },
                    options: ['inline', 'blockType', 'textAlign', 'history', 'link','list'],
                    blockType: {
                        inDropdown: true,
                        options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6', 'Blockquote'],
                        className: undefined,
                        component: undefined,
                        dropdownClassName: undefined,
                    }
                }}
                  onEditorStateChange={(editorState)=>{
                    onEditorStateChange(editorState, setFieldValue)
                  }}
                />
              </div>
              <Row>
                <Col>
                  <InputTextField label={"Company Email"} name={"email"} placeholder="Company Email" type={"email"} />
                </Col>
                <Col>
                  <InputTextField label={"Phone"} name={"phone"} placeholder="Phone" />
                </Col>
              </Row>
              <div>
                <InputTextField label={"Website"} name={"website"} placeholder="Website" />
              </div>
              <Row>
                <Col>
                  <div className="form-group">
                    <FileUploader
                      id={"profile_image"}
                      filePath={profileUrl || ""}
                      fileType={""}
                      label="Profile Image / Logo"
                      name="profile_image"
                      imageHeight={150}
                      imageWidth={150}
                      onFileChange={(file: any) => {
                        setFieldValue("profile_image", file)
                      }} />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <FileUploader
                      id={"header_image_mobile"}
                      filePath={mobileHeaderImage || ""}
                      fileType={""}
                      label="Banner Image (Mobile)"
                      name="mobile_header_image"
                      imageWidth={300}
                      imageHeight={150}
                      onFileChange={(file: any) => {
                        setFieldValue("mobile_header_image", file)
                      }} />
                  </div>
                </Col>
                <Col>
                  <div className="form-group">
                    <FileUploader
                      id={"header_image_desktop"}
                      filePath={headerUrl || ""}
                      fileType={""}
                      label="Banner Image (Desktop)"
                      name="header_image"
                      imageWidth={400}
                      imageHeight={150}
                      onFileChange={(file: any) => {
                        setFieldValue("header_image", file)
                      }} />
                  </div>
                </Col>
              </Row>

              <h4>User Details</h4>
              <Row>
                <Col>
                  <InputTextField autoCapitalize={"words"} label={"First Name"} name={"firstName"} placeholder="First Name" />
                </Col>

                <Col>
                  <InputTextField autoCapitalize={"words"} label={"Last Name"} name={"lastName"} placeholder="Last Name" />
                </Col>
              </Row>
              <div>
                <InputTextField label={"User Email"} name={"userEmail"} placeholder="User Email" type={"email"} />
              </div>
              {isSubmitting
                ?
                <Loader />
                :
                <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">
                  {type}
                </Button>
              }
              <Button onClick={() => {
                // isAdmin() ?
                //   window.location.assign("/company/list")
                //   :
                window.history.back()
              }} variant="outline-primary">Cancel</Button>
            </Form>
          </div>
        )}
    </Formik>
  )
}