import { getCookie, removeCookie, setCookie } from '../utils/cookie'
import { USER_STORAGE_KEY } from './auth.service'

export const ROLE_STORAGE_KEY = 'role'

export const saveRole = (role: string): void => {
  setCookie(ROLE_STORAGE_KEY, role)
}

export const getRole = (): string => {
  return JSON.parse(getCookie(USER_STORAGE_KEY)).role
}

export const isAdmin = (): boolean => {
  try {
    return JSON.parse(getCookie(USER_STORAGE_KEY)).role == 'admin'
  } catch (e) {
    return false
  }
}

export const isManager = (): boolean => {
  try {
    return JSON.parse(getCookie(USER_STORAGE_KEY)).role == 'company_manager'
  } catch (e) {
    return false
  }
}

export const removeRole = (): void => {
  removeCookie(ROLE_STORAGE_KEY)
}

export const isRestrictedRoute = (route: string): boolean => {
  const routeArray = [
    '/users/add',
    '/users/edit/:id',
    '/featured-videos/category',
    '/videomoderation/list',
    '/videomoderation/edit/:id',
    '/participants/list',
    '/participants/add',
    '/participants/edit/:id',
  ]
  return routeArray.indexOf(route) != -1
}
