import React, { FunctionComponent, useEffect, useState } from 'react'
import { deleteVideo, videos } from '../../../services/video.service'
import { TableWithFPS } from '../../../components/common/tablewithfps'
import Layout from '../../../components/layout'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
  getCoverImage,
  getQueryParams,
  getServerUrl,
  kFormatter,
  showError,
  showSuccess,
  utcToLocalDateTime,
} from '../../../utils/helper'
import { Button, Container } from 'react-bootstrap'
import { AlertModal } from '../../../components/common/alertmodal'

export const VideoAnalyticsList: FunctionComponent = () => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = queryString.parse(location.search)
  const [videoData, setVideos] = useState<any>({})
  const [loading, setLoading] = useState(false)
  const [query, setQuery] = useState({ ...queryParams })
  const [filterOpt, setFilterOpt] = useState({})
  const filterOptions = [{ name: "Title", id: "title" }, { name: "Company", id: "company" }]
  const [showModal, setShowModal] = useState(false)
  const [selectedRows, setSelectedRows] = useState([])
  const [deleteRow, setDeleteRow] = useState([])
  useEffect(() => {
    const queryString = getQueryParams(query)
    history.push({
      pathname: '/videos/analytics',
      search: queryString,
    })
  }, [query])

  useEffect(() => {
    if (query.filterBy) {
      const defaultOption = filterOptions.filter((item) => item.id === query.filterBy)
      if (defaultOption.length > 0) {
        setFilterOpt({
          column: defaultOption,
          filterText: query.filterValue
        })
      }
    }
    fetchVideos(location.search || '')
  }, [location])

  const fetchVideos = async (queryParams: string) => {
    if (loading) return
    setLoading(true)
    const videosData = await videos(queryParams)
    if (!videoData.error) {
      setVideos(videosData)
    }
    setLoading(false)
  }

  const createColumnHeaders = () => {
    return [
      {
        name: 'Video',
        selector: 'title',
        sortable: true,
        grow: 200,
        wrap: true,
        cell: (row: any) => {
          return (
            <div className="d-flex align-items-center">
              <div className="video-thumb">
                <div className="time-stamp">{row.video_length}</div>
                <img className="ml-1 video-img" src={
                    row.cover
                      ? getServerUrl(row.cover.path)
                      : getCoverImage(row.url)
                  } />
              </div>
              <div>{row.title}</div>
            </div>
          )
        },
      },
      {
        name: 'View',
        selector: 'viewCount',
        sortable: true,
        grow: 80,
        center: true,
      },
      {
        name: 'Comments',
        selector: 'commentCount',
        sortable: true,
        grow: 80,
        center: true,
      },
      {
        name: 'Likes',
        selector: 'likeCount',
        sortable: true,
        grow: 80,
        center: true,
      },
      {
        name: 'Category',
        selector: 'category.name',
        sortable: true,
        grow: 80,
        center: true,
      }
    ]
  }

  const onDeleteUser = async () => {
    let deleteObj = selectedRows
    if (deleteRow.length > 0) {
      deleteObj = deleteRow
    }
    const res = await deleteVideo({
      ids: deleteObj
    })
    if (!res.error) {
      showSuccess("Video deleted successfully")
      setSelectedRows([])
      setDeleteRow([])
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    } else {
      showError(res.message || "Something went wrong")
    }
  }

  return (
    <Layout>
      {
        <Container fluid>
          <div className="custom-tab"> 
              <div className="custom-tab--item">
                <Link to="/videos/list" className="">
                  <h2 className="custom-tab--text">Videos</h2>
                </Link>
              </div>
              <div className="custom-tab--item">
                <h2 className="custom-tab--text custom-tab--link" >Analytics</h2>
              </div>
              
            </div>
        </Container>
      }
      {
        <Container fluid>
          <TableWithFPS
            // title={"Videos"}
            loading={loading}
            columns={createColumnHeaders()}
            data={videoData.data}
            isFilterable={true}
            filterOptions={filterOptions}
            onSort={(columnName: any, sortDirection: string) => {
              const sortObj = {
                sortBy: columnName,
                order: sortDirection,
                page: '1',
              }
              setQuery({ ...query, ...sortObj })
            }}
            onFilter={(filterColumn: string, filterText: string) => {
              if (filterText != "") {
                const filterObj = {
                  filterBy: filterColumn,
                  filterValue: filterText,
                  page: '1'
                }
                setQuery({ ...query, ...filterObj })
              } else {
                const filterObj = {
                  page: '1'
                }
                delete query.filterBy
                delete query.filterValue
                setQuery({ ...query, ...filterObj })
              }

            }}
            defaultSortField={query.sortBy && query.sortBy.toString() || "id"}
            defaultSortAsc={query.order && query.order == "asc" || false}
            paginationPerPage={videoData.limit}
            paginationTotalRows={videoData.total}
            onPageChange={(page: number) => {
              setQuery({ ...query, page: `${page}` })
            }}
            filterOpt={filterOpt}
            paginationDefaultPage={videoData.currentPage}
            selectableRows={true}
            onSelectedRowsChange={(selectedRows: any) => { setSelectedRows(selectedRows) }}
          />
          <AlertModal
            show={showModal}
            bodyText={"Are you sure?"}
            positiveText={"Yes"}
            onPositive={async () => {
              await onDeleteUser()
              setShowModal(false)
            }
            }
            showNegative
            negativeText={"No"}
            onNegative={() => {
              setDeleteRow([])
              setShowModal(false)
            }}
          />
        </Container>
      }
    </Layout>
  )
}
