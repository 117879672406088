import React, { FunctionComponent, useEffect, useState } from 'react'
import { TableWithFPS } from '../../components/common/tablewithfps'
import Layout from '../../components/layout'
import { Link } from 'react-router-dom'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
    getQueryParams,
    showError,
    showSuccess,
    utcToLocalDateTime,
} from '../../utils/helper'
import { Button, Container } from 'react-bootstrap'
import { isAdmin } from '../../services/role.service'
import { AlertModal } from '../../components/common/alertmodal'
import { deleteCompany, getCompanyList } from '../../services/company.service'

export const CompanyList: FunctionComponent = () => {
    const history = useHistory()
    const location = useLocation()
    const queryParams = queryString.parse(location.search)
    const [companyData, setCompanyData] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({ ...queryParams })
    const [filterOpt, setFilterOpt] = useState({})
    const filterOptions = [{ name: "Name", id: "name" }, { name: "Summary", id: "description" }]
    const [showModal, setShowModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [deleteRow, setDeleteRow] = useState([])
    useEffect(() => {
        const queryString = getQueryParams(query)
        history.push({
            pathname: '/company/list',
            search: queryString,
        })
    }, [query])

    useEffect(() => {
        if (query.filterBy) {
            const defaultOption = filterOptions.filter((item) => item.id === query.filterBy)
            if (defaultOption.length > 0) {
                setFilterOpt({
                    column: defaultOption,
                    filterText: query.filterValue
                })
            }
        }
        fetchCompanies(location.search || '')
    }, [location])

    const fetchCompanies = async (queryParams: string) => {
        if (loading) return
        setLoading(true)
        const compnayData = await getCompanyList(queryParams)
        if (!compnayData.error) {
            setCompanyData(compnayData)
        }
        setLoading(false)
    }

    const createColumnHeaders = () => {
        return [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
            },
            {
                name: 'Name',
                selector: 'name',
                sortable: true,
                grow: 100
            },
            {
                name: 'Summary',
                selector: 'description',
                grow: 200,
                wrap: true,
                cell: (row: any) => {
                    return (row.description.substr(0,55)+'...').replace(/<[^>]*>?/gm, '')
                }
            },
            {
                name: 'No of Videos',
                selector: 'totalVideos',
                sortable: true,
                grow: 100
            },
            // {
            //     name: 'Company Manager',
            //     selector: 'category.name',
            //     sortable: true,
            // },
            {
                name: 'Last Video Added',
                selector: 'lastVideoAdded',
                sortable: true,
                grow: 100,
                cell: (row: any) => {
                    return utcToLocalDateTime(row.lastVideoAdded, 'DD/MM/yyyy')
                },
            },
            {
                name: 'Created',
                selector: 'created_at',
                sortable: true,
                grow: 100,
                cell: (row: any) => {
                    return utcToLocalDateTime(row.created_at, 'DD/MM/yyyy')
                },
            },
            {
                name: 'Action',
                button: true,
                // omit: omit,
                cell: (row: any) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                            className="text-primary cursor text-600"
                            onClick={() => {
                                window.location.href = `edit/${row.id}`
                            }}
                        >
                            <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_edit.svg")} />
                        </div>
                        {
                            isAdmin() ?
                                <div
                                    className="text-primary cursor text-600 ml-2"
                                    onClick={() => {
                                        const obj: any = [row.id]
                                        setDeleteRow(obj)
                                        setShowModal(true)
                                    }}>
                                    <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_delete.svg")} />
                                </div>
                                :
                                <div style={{ width: 30 }}></div>
                        }

                    </div>
                ),
            },
        ]
    }

    const onDeleteCompany = async () => {
        let deleteObj = selectedRows
        if (deleteRow.length > 0) {
            deleteObj = deleteRow
        }
        const res = await deleteCompany({
            ids: deleteObj
        })
        if (!res.error) {
            showSuccess("Company deleted successfully")
            setSelectedRows([])
            setDeleteRow([])
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            showError(res.message || "Something went wrong")
        }
    }

    return (
        <Layout>
            {
                isAdmin() && <Container fluid>
                       <div className="d-flex justify-content-between">
                            <h2>Companies</h2>
                            <div>
                                <span className="input-group-btn float-right mr-3 mb-3">
                                    <Button style={{ width: 100 }} disabled={selectedRows.length == 0} variant="primary" type="submit" className="mr-3" onClick={() => {
                                        setShowModal(true)
                                    }}>Delete</Button>
                                </span>
                                <span className="input-group-btn float-right mr-3 mb-3">
                                    <Link style={{ width: 100 }} to="/company/add" className="btn btn-primary btn-add">
                                        Add
                                    </Link>
                                </span>
                            </div>
                        </div>
                </Container>
            }
            {
                <Container fluid>
                    <TableWithFPS
                        // title={"Companies"}
                        loading={loading}
                        columns={createColumnHeaders()}
                        data={companyData.data}
                        isFilterable={true}
                        filterOptions={filterOptions}
                        onSort={(columnName: any, sortDirection: string) => {
                            const sortObj = {
                                sortBy: columnName,
                                order: sortDirection,
                                page: '1',
                            }
                            setQuery({ ...query, ...sortObj })
                        }}
                        onFilter={(filterColumn: string, filterText: string) => {
                            if (filterText != "") {
                                const filterObj = {
                                    filterBy: filterColumn,
                                    filterValue: filterText,
                                    page: '1'
                                }
                                setQuery({ ...query, ...filterObj })
                            } else {
                                const filterObj = {
                                    page: '1'
                                }
                                delete query.filterBy
                                delete query.filterValue
                                setQuery({ ...query, ...filterObj })
                            }

                        }}
                        defaultSortField={query.sortBy && query.sortBy.toString() || "id"}
                        defaultSortAsc={query.order && query.order == "asc" || false}
                        paginationPerPage={companyData.limit}
                        paginationTotalRows={companyData.total}
                        onPageChange={(page: number) => {
                            setQuery({ ...query, page: `${page}` })
                        }}
                        filterOpt={filterOpt}
                        paginationDefaultPage={companyData.currentPage}
                        selectableRows={isAdmin()}
                        onSelectedRowsChange={(selectedRows: any) => { setSelectedRows(selectedRows) }}
                    />
                    <AlertModal
                        show={showModal}
                        bodyText={"Are you sure?"}
                        positiveText={"Yes"}
                        onPositive={async () => {
                            await onDeleteCompany()
                            setShowModal(false)
                        }
                        }
                        showNegative
                        negativeText={"No"}
                        onNegative={() => {
                            setDeleteRow([])
                            setShowModal(false)
                        }}
                    />
                </Container>
            }
        </Layout>
    )
}
