import React, { FunctionComponent, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { logout } from '../../services/auth.service'

const Logout: FunctionComponent = () => {
    const history = useHistory()

    useEffect(() => {
        logout()
        history.push({
            pathname: '/login',
        })
    }, [])

    return (
        <div>
            Logging out...
        </div>
    )
}
export default Logout