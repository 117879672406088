import React, { FunctionComponent } from 'react';
import { useLocation } from 'react-router-dom';
import Layout from '../../components/layout';


export const Error:FunctionComponent = () =>{
    const location = useLocation();

    return (
        <Layout>
            <div>
                <h3>
                    No match for <code>{location.pathname}</code>
                </h3>
            </div>
        </Layout>
    );
}