import React, { FunctionComponent, useEffect, useState } from 'react'
import Layout from '../../../components/layout'
import {
  activeVideos,
  videoSuggestions,
  makeFeaturedVideos,
} from '../../../services/video.service'
import { showError, showSuccess } from '../../../utils/helper'
import { Col, Container, Row } from 'react-bootstrap'
import { TableWithFPS } from '../../../components/common/tablewithfps'
import AutoComplete from '../../../components/autocomplete'

const CompanyFeaturedVideos: FunctionComponent = () => {
  const [featuredVideos, setFeaturedVideos] = useState<any>({})
  const [featuredVideosFetched, setFeaturedVideosFetched] = useState(false)
  const [searchedVideos, setSearchedVideos] = useState<any>({})
  const [selectedVideo, setSelectedVideo] = useState<any>({})

  useEffect(() => {
    async function fetchInitialData() {
      await fetchFeaturedVideos()
    }

    fetchInitialData()
  }, [])

  const fetchFeaturedVideos = async () => {
    const response = await activeVideos('is_company_featured=1')
    if (response.error === true) {
      showError(response.message)
      return false
    }
    setFeaturedVideos(response.data)
    setFeaturedVideosFetched(true)
  }

  const onVideoSelection = async () => {
    const id = selectedVideo ? selectedVideo.value : null
    if (!id) {
      return false
    }
    const body = {
      key: 'is_company_featured',
      isFeatured: true,
      videoIds: [id],
    }

    const response = await makeFeaturedVideos(body)

    if (response.error === true) {
      showError(response.message)
      return false
    } else {
      showSuccess('Video added to Company Featured list successfully')
      setTimeout(() => {
        window.location.reload()
      }, 1000)
    }

    await fetchFeaturedVideos()
    setSearchedVideos([])
  }

  const createColumnHeaders = () => {
    return [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
      },
      {
        name: 'Title',
        selector: 'title',
        sortable: true,
        grow: 500,
        wrap: true,
        // cell: (row: any) => {
        //   return truncate(row.title, 30)
        // },
      },
      {
        name: 'Category',
        selector: 'category.name',
        sortable: true,
        grow: 100,
        wrap: true,
      },
    ]
  }

  return (
    <Layout>
      {/* <Container fluid className="input-group-btn">
        <div className="w-50 ml-auto">
          <Row>
            <Col xs={12} md={7} >
              <AutoComplete
                label="Search Videos"
                searchFunction={videoSuggestions}
                selectedValue={(selected: any) =>
                  onVideoSelection(selected.value)
                }
              />
            </Col>
          </Row>
        </div>
      </Container> */}
      <Container fluid>
        <div className="d-flex justify-content-between">
          <h2 className="page-title mb-3">Company Featured Videos</h2>
          <div></div>
        </div>
      </Container>
      {featuredVideosFetched && (
        <Container fluid>
          <div className="feature-video">
            <TableWithFPS
              // title={"Company Featured Videos"}
              loading={!featuredVideosFetched}
              columns={createColumnHeaders()}
              data={featuredVideos}
              isFilterable={true}
              defaultSortField="id"
              paginationPerPage={10}
              paginationTotalRows={featuredVideos.length}
              pagination={false}
              sortServer={false}
              defaultSortAsc={false}
              subHeaderComponent={
                <Row>
                  <Col style={{ width: 700 }}>
                    <AutoComplete
                      label="Search Videos"
                      searchFunction={videoSuggestions}
                      selectedValue={
                        (selected: any) => setSelectedVideo(selected)
                        //onVideoSelection(selected.value)
                      }
                    />
                  </Col>
                  <Col>
                    <button
                      className="btn btn-primary"
                      onClick={onVideoSelection}
                      style={{ marginTop: 38 }}
                    >
                      Add
                    </button>
                  </Col>
                </Row>
              }
            />
          </div>
        </Container>
      )}
    </Layout>
  )
}

export default CompanyFeaturedVideos
