import React, { FunctionComponent } from "react"
import Header from "../header"

const Layout: FunctionComponent = (props) => {

    return(
        <div>
            <Header></Header>
            <div className="">
                {props.children}
            </div>
            <hr className="p-0 m-0 mt-5"></hr>
            <footer className="container py-3 text-right text-muted">
            Copyright © 2020 The Webineo. All rights reserved.
            </footer>
        </div>
    )

}

export default Layout