import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../common';
import FileUploader from '../../common/fileuploader';
import { Button } from 'react-bootstrap';
import { getFormattedOptionsData } from '../../../utils/helper';
import { Link } from 'react-router-dom';
import Loader from '../../loader';
interface IProfile {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    role?: string;
    company_id?: Array<any>
    isActive?: number;
    profilePic?: File;
    serverUrl?: string
    fileType?: string
    isEdit?: boolean
    type: string
    onSubmit: (values: any) => Promise<boolean>
}

const getSchema = (isEdit = false) => {
    const schemaObj: any = {
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        password: Yup.string().required("Password is required").min(5),
        isActive: Yup.boolean(),
        profilePic: Yup.mixed(),
        oldPassword: Yup.string(),
        newPassword: Yup.string(),
        passwordConfirmation: Yup.string()
    }
    if (isEdit) {
        delete schemaObj.password
    }
    return Yup.object().shape(schemaObj);
}


export const ProfileForm = (props: IProfile) => {
    const { email, firstName, lastName, password, role, company_id, profilePic, serverUrl, fileType, isEdit, type, onSubmit } = props
    const UserSchema = getSchema(isEdit)
    const [companyError, setCompanyError] = useState(false)
    const [changePassword, setChangePassword] = useState(false)
    const [oldPassword, setOldPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")

    const handlePasswordChange = () => {
        setChangePassword(!changePassword)
    }
    return (<Formik
        initialValues={{
            email: email || "",
            firstName: firstName || "",
            lastName: lastName || "",
            password: password || "",
            role: role || "",
            company_id: company_id || [],
            profilePic: profilePic || null,
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        }}
        validationSchema={UserSchema}
        onSubmit={async (values, actions) => {
            try {
                let valid = await UserSchema.isValid(values);
                if (values.role == "company_manager" && values.company_id.length <= 0) {
                    valid = false;
                    setCompanyError(true)
                } else {
                    setCompanyError(false)
                    valid = true
                }
                if (changePassword) {
                    let count = 0
                    if (values.oldPassword?.trim().length < 5) {
                        count++
                        setOldPassword("Current Password is too short - should be 5 chars minimum")
                    } else {
                        setOldPassword("")
                    }

                    if (values.newPassword?.trim().length < 5) {
                        count++
                        setNewPassword("New Password is too short - should be 5 chars minimum")
                    } else if (values.oldPassword?.trim() === values.newPassword?.trim()) {
                        count++
                        setNewPassword("New password must be different from Current password")
                    }
                    else {
                        setNewPassword("")
                    }

                    if (values.confirmPassword?.trim().length < 5 || values.confirmPassword?.trim() != values.newPassword?.trim()) {
                        count++
                        setConfirmPassword("Passwords must match")
                    } else {
                        setConfirmPassword("")
                    }
                    if (count > 0) {
                        valid = false
                    } else {
                        valid = true
                    }
                }
                if (valid) {
                    const res: boolean = await onSubmit(values)
                    actions.setSubmitting(false);
                    if (res) {
                        actions.resetForm({
                            values: {
                                // the type of `values` inferred to be Blog
                                email: '',
                                firstName: '',
                                lastName: '',
                                role: '',
                                company_id: [],
                                password: '',
                                profilePic: null,
                                oldPassword: '',
                                newPassword: '',
                                confirmPassword: ''
                            },
                            // you can also set the other form states here
                        });
                        // window.location.reload()
                    }
                }
                console.log(valid, values);
            } catch (error) {
                console.log(error);
            }

        }}
    >
        {({
            isSubmitting,
            setFieldValue
            /* and other goodies */
        }) => (
                <div className="mb-3">
                    <Form>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"First Name"} name={"firstName"} placeholder="First Name" />
                        </div>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"Last Name"} name={"lastName"} placeholder="Last Name" />
                        </div>
                        <div>
                            <InputTextField label={"Email"} name={"email"} placeholder="Email" type={"email"} />
                        </div>
                        {
                            !isEdit && <div>
                                <InputTextField label={"Password"} type={"password"} name={"password"} placeholder="Password" />
                            </div>
                        }
                        <div>
                            <FileUploader filePath={serverUrl || ""} fileType={fileType || ""} label="Profile Pic" name="profilePic" onFileChange={(file: any) => { setFieldValue("profilePic", file) }} />
                        </div>
                        <div className="mt-5 mb-5">
                            <Button onClick={handlePasswordChange}>Change Password</Button>
                            {
                                changePassword &&
                                <div className="mt-3">
                                    <div>
                                        <InputTextField label={"Current Password"} name={"oldPassword"} placeholder="" type={"password"} />
                                        <div className="text-danger">
                                            {oldPassword != "" && <span>{oldPassword}</span>}
                                        </div>
                                    </div>
                                    <div>
                                        <InputTextField label={"New Password"} name={"newPassword"} placeholder="" type={"password"} />
                                        <div className="text-danger">
                                            {newPassword != "" && <span>{newPassword}</span>}
                                        </div>
                                    </div>
                                    <div>
                                        <InputTextField label={"Confirm Password"} name={"confirmPassword"} placeholder="" type={"password"} />
                                        <div className="text-danger">
                                            {confirmPassword != "" && <span>{confirmPassword}</span>}
                                        </div>
                                    </div>
                                </div>
                            }

                        </div>


                        {isSubmitting
                            ?
                            <Loader />
                            :
                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">{type}</Button>
                        }
                        <Link className="ml-2" to="/dashboard">
                            <Button variant="outline-primary">Cancel</Button>
                        </Link>
                    </Form>
                </div>
            )}
    </Formik>)
}