import React, { useState } from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { Checkbox, InputTextField } from '../common';
import FileUploader from '../../common/fileuploader';
// import MultiSelectCompany from '../../multiselectcompany';
import { Button } from 'react-bootstrap';
// import SelectOption from '../../common/selectoption';
import { getFormattedOptionsData } from '../../../utils/helper';
import Loader from '../../loader';
interface IUser {
    email?: string;
    firstName?: string;
    lastName?: string;
    password?: string;
    role?: string;
    company_id?: Array<any>
    isActive?: number;
    profilePic?: File;
    serverUrl?: string
    fileType?: string
    isEdit?: boolean
    type: string
    onSubmit: (values: any) => Promise<boolean>
}

const getSchema = (isEdit = false) => {
    const schemaObj: any = {
        email: Yup.string().email('Invalid email address').required('Email is required'),
        firstName: Yup.string().required('First Name is required'),
        lastName: Yup.string().required('Last Name is required'),
        password: Yup.string().required("Password is required").min(5),
        // role: Yup.string().required('Role is required'),
        // company_id: Yup.array(),
        isActive: Yup.boolean(),
        profilePic: Yup.mixed()
    }
    if (isEdit) {
        delete schemaObj.password
    }
    // if (!isAdmin()) {
    //     delete schemaObj.role
    //     delete schemaObj.company_id
    // }
    return Yup.object().shape(schemaObj);
}

export const UserForm = (props: IUser) => {
    const { email, firstName, lastName, password, role, company_id, isActive, profilePic, serverUrl, fileType, isEdit, type, onSubmit } = props
    const UserSchema = getSchema(isEdit)
    const [companyState, setCompanyState] = useState(company_id)
    const [companyError, setCompanyError] = useState(false)
    const roleOptions = getFormattedOptionsData([
        { name: "Select Role", id: "" },
        { name: "Admin", id: "admin" },
        { name: "Company Manager", id: "company_manager" }
    ])
    return (<Formik
        initialValues={{
            email: email || "",
            firstName: firstName || "",
            lastName: lastName || "",
            password: password || "",
            role: role || "",
            company_id: company_id || [],
            isActive: Boolean(isActive) || false,
            profilePic: profilePic || null,
        }}
        validationSchema={UserSchema}
        onSubmit={async (values, actions) => {
            try {
                let valid = await UserSchema.isValid(values);
                if (values.role == "company_manager" && values.company_id.length <= 0) {
                    valid = false;
                    setCompanyError(true)
                } else {
                    setCompanyError(false)
                    valid = true
                }
                if (valid) {
                    const res: boolean = await onSubmit(values)
                    actions.setSubmitting(false);
                    if (res && !isEdit) {
                        actions.resetForm({
                            values: {
                                // the type of `values` inferred to be Blog
                                email: '',
                                firstName: '',
                                lastName: '',
                                role: '',
                                company_id: [],
                                password: '',
                                isActive: false,
                                profilePic: null
                            },
                            // you can also set the other form states here
                        });
                        // window.location.reload()
                    }
                }
                console.log(valid, values);
            } catch (error) {
                console.log(error);
            }

        }}
    >
        {({
            values,
            isSubmitting,
            setFieldValue
            /* and other goodies */
        }) => (
                <div className="mb-3">
                    <Form>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"First Name"} name={"firstName"} placeholder="First Name" />
                        </div>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"Last Name"} name={"lastName"} placeholder="Last Name" />
                        </div>
                        <div>
                            <InputTextField label={"Email"} name={"email"} placeholder="Email" type={"email"} />
                        </div>
                        {
                            !isEdit && <div>
                                <InputTextField label={"Password"} type={"password"} name={"password"} placeholder="Password" />
                            </div>
                        }
                        {/* <div>
                            {
                                isAdmin()
                                &&
                                <div className="form-group">
                                    <label>Role</label>
                                    <SelectOption
                                        options={roleOptions}
                                        value={roleOptions.filter((item: any) => item.value == values.role)}
                                        onChange={(valueObj: any) => setFieldValue("role", valueObj.value)}
                                    />
                                    <div className="text-danger">
                                        <ErrorMessage name={"role"} />
                                    </div>
                                </div>
                            }
                        </div>
                        {
                            isAdmin() && values.role == "company_manager" &&
                            <div className="form-group">
                                <MultiSelectCompany
                                    name="company_id"
                                    onChange={(value) => setFieldValue('company_id', value)}
                                    selectedOptions={companyState}
                                />
                                {
                                    companyError && <div className="text-danger">
                                        <span>Company is required</span>
                                    </div>
                                }
                            </div>
                        } */}
                        <div>
                            <FileUploader filePath={serverUrl || ""} fileType={fileType || ""} label="Profile Pic" name="profilePic" onFileChange={(file: any) => { setFieldValue("profilePic", file) }} />
                        </div>
                        <div className="mt-2">
                            <Checkbox name="isActive" labelname={"Active"} checked={values.isActive} />
                        </div>


                        {isSubmitting
                            ?
                            <Loader />
                            :
                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">{type}</Button>
                        }
                        <Button onClick={() => {
                            window.history.back()
                        }} variant="outline-primary">Cancel</Button>
                    </Form>
                </div>
            )}
    </Formik>)
}