import React, { FunctionComponent } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { CompanyForm } from '../../../components/forms/company';
import Layout from '../../../components/layout';
import { addCompany } from '../../../services/company.service';
import { uploadFile } from '../../../services/file.service';
import { showError, showSuccess } from '../../../utils/helper';

export const AddCompany: FunctionComponent = () => {
    const history = useHistory()
    let profileId = 0, headerId = 0, mobileHeaderId = 0;

    const onFormSubmit = async (values: any) => {
        if (profileId == 0 && values.profile_image && values.profile_image instanceof File) {
            const formData = new FormData()
            formData.set('file', values.profile_image)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                profileId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }
        }

        if (headerId == 0 && values.header_image && values.header_image instanceof File) {
            const formData = new FormData()
            formData.set('file', values.header_image)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                headerId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }
        }

        if (mobileHeaderId == 0 && values.mobile_header_image && values.mobile_header_image instanceof File) {
            const formData = new FormData()
            formData.set('file', values.mobile_header_image)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                mobileHeaderId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }
        }

        if (profileId != 0) {
            values.profile_image = profileId
        }

        if (headerId != 0) {
            values.header_image = headerId
        }

        if (mobileHeaderId != 0) {
            values.mobile_header_image = mobileHeaderId
        }

        const reqData = {
            "company": {
                "name": values.name,
                "description": values.description,
                "profile_image": values.profile_image,
                "header_image": values.header_image,
                "mobile_header_image": values.mobile_header_image,
                "email": values.email,
                "phone": values.phone,
                "website": values.website
            },
            "user": {
                "firstName": values.firstName,
                "lastName": values.lastName,
                "role": "company_manager",
                "email": values.userEmail
            }

        }
        const response = await addCompany(reqData)
        if (!response.error) {
            showSuccess("Company added successfully")
            headerId = 0
            profileId = 0
            setTimeout(() => {
                history.push({
                    pathname: '/company/list'
                })
            }, 100)
            return true
        } else {
            showError(response.message)
            return false
        }

    }

    return (
        <Layout>
            <Container className="w-50 mr-auto">
                <h2 className="mb-5">Add a Company</h2>
                <CompanyForm
                    onSubmit={async (values) => await onFormSubmit(values)}
                    type="Add"
                />
            </Container>
        </Layout >
    )
};