import React from 'react';

import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../common';
import FileUploader from '../../common/fileuploader';
import { Button } from 'react-bootstrap';
import Loader from '../../loader';
interface IUser {
    email?: string;
    name?: string;
    phone_number?: string;
    profile_pic?: File;
    serverUrl?: string
    isEdit?: boolean
    type: string
    onSubmit: (values: any) => Promise<boolean>
}

const getSchema = () => {
    const schemaObj: any = {
        email: Yup.string().email('Invalid email address'),
        name: Yup.string().required('First Name is required'),
        phone_number: Yup.string().matches(/^[0-9]*$/, 'Enter valid phone number'),
        profile_pic: Yup.mixed()
    }
    return Yup.object().shape(schemaObj);
}

export const ParticipantForm = (props: IUser) => {
    const { email, name, phone_number, profile_pic, serverUrl, isEdit, type, onSubmit } = props
    const UserSchema = getSchema()

    return (<Formik
        initialValues={{
            email: email || "",
            name: name || "",
            phone_number: phone_number || "",
            profile_pic: profile_pic || null,
        }}
        validationSchema={UserSchema}
        onSubmit={async (values, actions) => {
            try {
                const valid = await UserSchema.isValid(values);
                if (valid) {
                    const res: boolean = await onSubmit(values)
                    actions.setSubmitting(false);
                    if (res && !isEdit) {
                        actions.resetForm({
                            values: {
                                email: "",
                                name: "",
                                phone_number: "",
                                profile_pic: null
                            },
                            // you can also set the other form states here
                        });
                        // window.location.reload()
                    }
                }
                console.log(valid, values);
            } catch (error) {
                console.log(error);
            }

        }}
    >
        {({
            isSubmitting,
            setFieldValue
            /* and other goodies */
        }) => (
                <div className="mb-3">
                    <Form>
                        <div>
                            <InputTextField autoCapitalize={"words"} label={"Name"} name={"name"} placeholder="Name" />
                        </div>
                        <div>
                            <InputTextField label={"Email"} name={"email"} placeholder="Email" type={"email"} />
                        </div>
                        <div>
                            <InputTextField label={"Phone"} name={"phone_number"} placeholder="Phone" />
                        </div>
                        <div className="mb-3">
                            <FileUploader filePath={serverUrl || ""} fileType={""} label="Profile Pic" name="profile_pic" onFileChange={(file: any) => { setFieldValue("profile_pic", file) }} />
                        </div>
                        {isSubmitting
                            ?
                            <Loader />
                            :
                            <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">{type}</Button>
                        }
                        <Button onClick={() => {
                            window.history.back()
                        }} variant="outline-primary">Cancel</Button>
                    </Form>
                </div>
            )}
    </Formik>)
}