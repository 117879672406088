import React, { FunctionComponent } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { InputTextField } from '../../components/forms/common';
import { useHistory } from 'react-router-dom';
import Layout from '../../components/layout';
import { Button, Card, Container } from 'react-bootstrap';
import Loader from '../../components/loader';
import { login } from '../../services/auth.service';
import { showError } from '../../utils/helper';

const LoginSchema = Yup.object().shape({
  password: Yup.string().required().min(5),
  email: Yup.string().email('Invalid email address').required('Email is required')
});
export const Login: FunctionComponent = () => {

  const history = useHistory();

  return (
    <Layout>
      <Formik
        initialValues={{
          email: '',
          password: ''
        }}
        validationSchema={LoginSchema}
        onSubmit={async (values, actions) => {
          try {
            const valid = await LoginSchema.isValid(values);
            if (valid) {
              const res = await login(values)
              if (res) {
                history.push("/videos");
              }
              else {
                showError("Invalid Credentials")
              }
            }
            setTimeout(() => {
              actions.setSubmitting(false);
              actions.resetForm({
                values: {
                  // the type of `values` inferred to be Blog
                  email: '',
                  password: ''
                },
                // you can also set the other form states here
              });
            }, 1000);
            console.log(valid);
          } catch (error) {
            console.log(error);
          }

        }}
      >
        {({
          isSubmitting,
        }) => (
            <Container className="p-3 mx-auto">
              <Card body className="w-50 mx-auto">
                <Form>
                  <div>
                    <InputTextField label={"Email"} name={"email"} type="email" placeholder="Email" />
                  </div>


                  <div>
                    <InputTextField label={"Password"} name={"password"} type="password" placeholder="Password" />
                  </div>
                  {
                    isSubmitting ?
                      <Loader />
                      :
                      <Button variant="primary" type="submit" disabled={isSubmitting}>Login</Button>
                  }
                </Form>
              </Card>

            </Container>
          )}
      </Formik>
    </Layout>
  )

}
