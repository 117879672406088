import React, { FunctionComponent, useEffect, useState } from 'react'
import { changeModerateState, deleteVideo, getModerateVideos } from '../../services/video.service'
import { TableWithFPS } from '../../components/common/tablewithfps'
import Layout from '../../components/layout'
import { useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'
import {
    getQueryParams,
    showError,
    showSuccess,
    utcToLocalDateTime,
} from '../../utils/helper'
import { Button, Container } from 'react-bootstrap'
import { isAdmin } from '../../services/role.service'
import { AlertModal } from '../../components/common/alertmodal'

export const VideoModeration: FunctionComponent = () => {
    const history = useHistory()
    const location = useLocation()
    const queryParams = queryString.parse(location.search)
    const [videoData, setVideos] = useState<any>({})
    const [loading, setLoading] = useState(false)
    const [query, setQuery] = useState({ ...queryParams })
    const [filterOpt, setFilterOpt] = useState({})
    const filterOptions = [{ name: "Title", id: "title" }, { name: "Company", id: "company" }]
    const [showModal, setShowModal] = useState(false)
    const [selectedRows, setSelectedRows] = useState([])
    const [publishRow, setPublishRow] = useState([])
    const [deleteRow, setDeleteRow] = useState([])
    const [actionType, setActionType] = useState("")
    useEffect(() => {
        const queryString = getQueryParams(query)
        history.push({
            pathname: '/videomoderation/list',
            search: queryString,
        })
    }, [query])

    useEffect(() => {
        if (query.filterBy) {
            const defaultOption = filterOptions.filter((item) => item.id === query.filterBy)
            if (defaultOption.length > 0) {
                setFilterOpt({
                    column: defaultOption,
                    filterText: query.filterValue
                })
            }
        }
        fetchVideos(location.search || '')
    }, [location])

    const fetchVideos = async (queryParams: string) => {
        if (loading) return
        setLoading(true)
        const videosData = await getModerateVideos(queryParams)
        if (!videoData.error) {
            setVideos(videosData)
        }
        setLoading(false)
    }

    const createColumnHeaders = () => {
        return [
            {
                name: 'Id',
                selector: 'id',
                sortable: true,
            },
            {
                name: 'Title',
                selector: 'title',
                sortable: true,
                grow: 200,
                wrap: true,
            },
            {
                name: 'Company',
                selector: 'company.name',
                sortable: true,
                grow: 150,
                wrap: true,
            },
            {
                name: 'Category',
                selector: 'category.name',
                sortable: true,
                grow: 100,
            },
            {
                name: 'Duration',
                selector: 'video_length',
                grow: 100,
            },
            // {
            //     name: 'Reactions',
            //     selector: 'likeCount',
            //     grow: 150,
            //     cell: (row: any) => {
            //         return (
            //             <div style={{ display: "flex", flex: 1, flexDirection: "row" }}>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyItems: "center" }}>
            //                     <span >{kFormatter(row.likeCount)}</span><img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_like.svg")} />
            //                 </div>

            //                 <div style={{ display: "flex", flexDirection: "row", justifyItems: "center" }}>
            //                     <span className="ml-2">{kFormatter(row.viewCount)}</span><img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_view.svg")} />
            //                 </div>
            //                 <div style={{ display: "flex", flexDirection: "row", justifyItems: "center" }}>
            //                     <span className="ml-2">{kFormatter(row.commentCount)}</span><img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_comment.svg")} />
            //                 </div>
            //             </div>
            //         )
            //     },
            // },
            // {
            //     name: 'Published',
            //     selector: 'is_active',
            //     grow: 100,
            //     cell: (row: any) => {
            //         return row.is_active ? 'Yes' : 'No'
            //     },
            // },
            {
                name: 'Featured',
                selector: 'is_featured',
                sortable: true,
                grow: 50,
                cell: (row: any) => {
                    return row.is_featured ? 'Yes' : 'No'
                },
            },
            {
                name: 'Created At',
                selector: 'created_at',
                sortable: true,
                grow: 100,
                cell: (row: any) => {
                    return utcToLocalDateTime(row.created_at, 'DD/MM/yyyy')
                },
            },
            {
                name: 'Action',
                button: true,
                cell: (row: any) => (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                        <div
                            className="text-primary cursor text-600"
                            onClick={() => {
                                window.location.href = `edit/${row.id}`
                            }}
                        >
                            <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_edit.svg")} />
                        </div>
                        <div
                            className="text-primary cursor text-600 ml-2"
                            onClick={() => {
                                const obj: any = [row.id]
                                setActionType("delete")
                                setDeleteRow(obj)
                                setShowModal(true)
                            }}>
                            <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../assets/icon_delete.svg")} />
                        </div>
                    </div>
                ),
            },
        ]
    }

    const onPublishVideo = async () => {
        let publishObj = selectedRows
        if (publishRow.length > 0) {
            publishObj = publishRow
        }
        const res = await changeModerateState({
            "videoIds": publishObj,
            "isActive": true
        })
        if (!res.error) {
            showSuccess("Video Published successfully")
            setSelectedRows([])
            setPublishRow([])
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            showError(res.message || "Something went wrong")
        }
    }

    const onDeleteVideo = async () => {
        let deleteObj = selectedRows
        if (deleteRow.length > 0) {
            deleteObj = deleteRow
        }
        const res = await deleteVideo({
            ids: deleteObj
        })
        if (!res.error) {
            showSuccess("Video deleted successfully")
            setSelectedRows([])
            setDeleteRow([])
            setTimeout(() => {
                window.location.reload()
            }, 1000)
        } else {
            showError(res.message || "Something went wrong")
        }
    }

    return (
        <Layout>
            {
                isAdmin() && <Container fluid>
                    <div className="d-flex justify-content-between">
                            <h2>Video Moderation</h2>
                            <div>
                                <span className="input-group-btn float-right mr-3 mb-3">
                                    <Button style={{ width: 100 }} variant="danger" disabled={selectedRows.length <= 0} type="submit" className="mr-3" onClick={() => {
                                        setActionType("delete")
                                        setShowModal(true)
                                    }}>Delete</Button>
                                </span>
                                <span className="input-group-btn float-right mr-3 mb-3">
                                    <Button style={{ width: 100 }} variant="primary" disabled={selectedRows.length <= 0} type="submit" className="mr-3" onClick={() => {
                                        setActionType("publish")
                                        setShowModal(true)
                                    }}>Publish</Button>
                                </span>
                            </div>
                    </div>
                    
                </Container>
            }
            {
                <Container fluid>
                    <TableWithFPS
                        // title={"Video Moderation"}
                        loading={loading}
                        columns={createColumnHeaders()}
                        data={videoData.data}
                        isFilterable={true}
                        filterOptions={filterOptions}
                        onSort={(columnName: any, sortDirection: string) => {
                            const sortObj = {
                                sortBy: columnName,
                                order: sortDirection,
                                page: '1',
                            }
                            setQuery({ ...query, ...sortObj })
                        }}
                        onFilter={(filterColumn: string, filterText: string) => {
                            if (filterText != "") {
                                const filterObj = {
                                    filterBy: filterColumn,
                                    filterValue: filterText,
                                    page: '1'
                                }
                                setQuery({ ...query, ...filterObj })
                            } else {
                                const filterObj = {
                                    page: '1'
                                }
                                delete query.filterBy
                                delete query.filterValue
                                setQuery({ ...query, ...filterObj })
                            }

                        }}
                        defaultSortField={query.sortBy && query.sortBy.toString() || "id"}
                        defaultSortAsc={query.order && query.order == "asc" || false}
                        paginationPerPage={videoData.limit}
                        paginationTotalRows={videoData.total}
                        onPageChange={(page: number) => {
                            setQuery({ ...query, page: `${page}` })
                        }}
                        filterOpt={filterOpt}
                        paginationDefaultPage={videoData.currentPage}
                        selectableRows={true}
                        onSelectedRowsChange={(selectedRows: any) => { setSelectedRows(selectedRows) }}
                    />
                    <AlertModal
                        show={showModal}
                        bodyText={"Are you sure?"}
                        positiveText={"Yes"}
                        onPositive={async () => {
                            if (actionType == "delete") {
                                await onDeleteVideo()
                            } else if (actionType == "publish") {
                                await onPublishVideo()
                            }
                            setShowModal(false)
                        }
                        }
                        showNegative
                        negativeText={"No"}
                        onNegative={() => {
                            setActionType("")
                            setDeleteRow([])
                            setPublishRow([])
                            setShowModal(false)
                        }}
                    />
                </Container>
            }
        </Layout>
    )
}
