import React, { useEffect, useState } from 'react';
import Layout from '../../../components/layout';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { TableWithFPS } from '../../../components/common/tablewithfps';
import queryString from "query-string";
import { deleteUser, users } from '../../../services/user.service';
import { getQueryParams, showError, showSuccess, toCamel, utcToLocalDateTime } from '../../../utils/helper';
import { Button, Container } from 'react-bootstrap';
import { isAdmin, isManager } from '../../../services/role.service';
import { AlertModal } from '../../../components/common/alertmodal';
import { getCookie } from '../../../utils/cookie';
import { USER_STORAGE_KEY } from '../../../services/auth.service';

export const UsersList = (): any => {
      const history = useHistory();
      const location = useLocation();
      const queryParams = queryString.parse(location.search);
      const [userData, setUserData] = useState<any>({})
      const [loading, setLoading] = useState(false)
      const [query, setQuery] = useState({ ...queryParams })
      const [filterOpt, setFilterOpt] = useState({})
      const filterOptions = [{ name: "First Name", id: "firstName" }, { name: "Last Name", id: "lastName" }, { name: "Email", id: "email" }]
      const [showModal, setShowModal] = useState(false)
      const [selectedRows, setSelectedRows] = useState([])
      const [deleteRow, setDeleteRow] = useState([])
      useEffect(() => {
            const queryString = getQueryParams(query);
            history.push({
                  pathname: '/users/list',
                  search: queryString
            })
      }, [query])
      useEffect(() => {
            if (query.filterBy) {
                  const defaultOption = filterOptions.filter((item) => item.id === query.filterBy)
                  if (defaultOption.length > 0) {
                        setFilterOpt({
                              column: defaultOption,
                              filterText: query.filterValue
                        })
                  }
            }
            fetchUsers(location.search || "")
      }, [location])

      const fetchUsers = async (queryParams: string) => {
            if (loading) return
            setLoading(true)
            const usersData = await users(queryParams)
            if (!usersData.error) {
                  setUserData(usersData)
                  // setResetPaginationToggle(!resetPaginationToggle)
            }
            setLoading(false)
      }

      const createColumnHeaders = () => {
            const omit = isManager()
            let userId = 0
            try {
                  userId = JSON.parse(getCookie(USER_STORAGE_KEY)).id
            } catch (e) { }
            return [
                  {
                        name: 'Id',
                        selector: 'id',
                        sortable: true,
                  },
                  {
                        name: 'First Name',
                        selector: 'firstName',
                        sortable: true,
                        grow: 10
                  },
                  {
                        name: 'Last Name',
                        selector: 'lastName',
                        sortable: true,
                        grow: 10
                  },
                  {
                        name: 'Email',
                        selector: 'email',
                        sortable: true,
                        wrap: true,
                        grow: 20
                  },
                  {
                        name: 'Role',
                        selector: 'role',
                        sortable: true,
                        grow: 10,
                        cell: (row: any) => {
                              return toCamel(row.role)
                        },
                  },
                  {
                        name: 'Last Activity',
                        selector: 'last_activity_at',
                        sortable: true,
                        grow: 10,
                        cell: (row: any) => {
                              return utcToLocalDateTime(row.last_activity_at, "DD/MM/yyyy")
                        },
                  },
                  {
                        name: 'Created At',
                        selector: 'created_at',
                        sortable: true,
                        grow: 10,
                        cell: (row: any) => {
                              return utcToLocalDateTime(row.created_at, "DD/MM/yyyy")
                        },
                  },
                  {
                        name: 'Action',
                        button: true,
                        omit: omit,
                        cell: (row: any) => (
                              <div style={{ display: "flex", flexDirection: "row" }}>
                                    <div
                                          className="text-primary cursor text-600"
                                          onClick={() => {
                                                window.location.href = `edit/${row.id}`
                                          }}
                                    >
                                          <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../../assets/icon_edit.svg")} />
                                    </div>
                                    {
                                          userId != row.id ?
                                                <div
                                                      className="text-primary cursor text-600 ml-2"
                                                      onClick={() => {
                                                            const obj: any = [row.id]
                                                            setDeleteRow(obj)
                                                            setShowModal(true)
                                                      }}>
                                                      <img className="ml-1" style={{ width: 20, height: 20 }} src={require("../../../assets/icon_delete.svg")} />
                                                </div>
                                                :
                                                <div style={{ width: 30 }}></div>
                                    }
                              </div>
                        ),
                  }
            ]
      }

      const onDeleteUser = async () => {
            let deleteObj = selectedRows
            if (deleteRow.length > 0) {
                  deleteObj = deleteRow
            }
            const res = await deleteUser({
                  ids: deleteObj
            })
            if (!res.error) {
                  showSuccess("User deleted successfully")
                  setSelectedRows([])
                  setDeleteRow([])
                  setTimeout(() => {
                        window.location.reload()
                  }, 1000)
            } else {
                  showError(res.message || "Something went wrong")
            }
      }
      return (<Layout>
            {
                  isAdmin() && <Container fluid>
                        <div className="d-flex justify-content-between">
                              <h2>Users</h2>
                              <div>
                                    <span className="input-group-btn float-right mr-3 mb-3">
                                          <Button style={{ width: 100 }} variant="danger" disabled={selectedRows.length <= 0} type="submit" className="" onClick={() => {
                                                setShowModal(true)
                                          }}>Delete</Button>
                                    </span>

                                    <span className="input-group-btn float-right mr-3 mb-3">
                                          <Link style={{ width: 100 }} to="/users/add" className="btn btn-primary btn-add"> Add </Link>
                                    </span>
                              </div>
                             
                        </div>
                  </Container >
            }
            {(
                  <Container fluid className="mb-3">

                        <TableWithFPS
                              // title={"Users"}
                              loading={loading}
                              columns={createColumnHeaders()}
                              data={userData.data}
                              isFilterable={true}
                              filterOptions={filterOptions}
                              onSort={(columnName: any, sortDirection: string) => {
                                    const sortObj = {
                                          "sortBy": columnName,
                                          "order": sortDirection,
                                          "page": "1"
                                    }
                                    setQuery({ ...query, ...sortObj })
                              }}
                              onFilter={(filterColumn: string, filterText: string) => {
                                    if (filterText != "") {
                                          const filterObj = {
                                                filterBy: filterColumn,
                                                filterValue: filterText,
                                                page: '1'
                                          }
                                          setQuery({ ...query, ...filterObj })
                                    } else {
                                          const filterObj = {
                                                page: '1'
                                          }
                                          delete query.filterBy
                                          delete query.filterValue
                                          setQuery({ ...query, ...filterObj })
                                    }
                              }}
                              defaultSortField={query.sortBy && query.sortBy.toString() || "id"}
                              defaultSortAsc={query.order && query.order == "asc" || false}
                              paginationPerPage={userData.limit}
                              paginationTotalRows={userData.total}
                              onPageChange={(page: number) => {
                                    setQuery({ ...query, page: `${page}` })
                              }}
                              filterOpt={filterOpt}
                              selectableRows={isAdmin()}
                              onSelectedRowsChange={(selectedRows: any) => { setSelectedRows(selectedRows) }}
                              paginationDefaultPage={userData.currentPage}
                        />
                        <AlertModal
                              show={showModal}
                              bodyText={"Are you sure?"}
                              positiveText={"Yes"}
                              onPositive={async () => {
                                    await onDeleteUser()
                                    setShowModal(false)
                              }
                              }
                              showNegative
                              negativeText={"No"}
                              onNegative={() => {
                                    setDeleteRow([])
                                    setShowModal(false)
                              }}
                        />
                  </Container>

            )}
      </Layout>)

}