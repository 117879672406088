import React, { ReactElement, useEffect, useState } from 'react'

import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { InputTextField, MaskedInputTextField, MultiTextField } from '../common'
import Tags from '../../tags'
import Participants from '../../participants'
import Companies from '../../company'
import Categories from '../../category'
import FileUploader from '../../common/fileuploader'
import { isAdmin } from '../../../services/role.service'
import { Button, Col, Row } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Loader from '../../loader'
interface IVideo {
  // data?: {}
  title?: string
  summary?: string
  url?: string
  transcript_path?: File
  cover_image?: File
  transcriptUrl?: string
  coverUrl?: string
  company_id?: number
  category_id?: number
  participants?: any
  tags?: any
  video_length?: string
  is_featured?: boolean
  is_company_featured?: boolean
  type: string
  isEdit?: boolean
  onSubmit: (values: any) => Promise<boolean>
}
const getSchema = (transcriptUrl: string) => {
  const schemaObj: any = {
    title: Yup.string().required('Title is required'),
    summary: Yup.string().required('Summary is required'),
    url: Yup.string().required("Video URL is required").url('Provide a valid URL for video'),
    transcript_path: Yup.mixed(),
    company_id: Yup.number()
      .positive('Please select company')
      .required('Please select company'),
    category_id: Yup.number()
      .positive('Please select company')
      .required('Please select category'),
    participants: Yup.array(),
    tags: Yup.array(),
    video_length: Yup.string().required('Video duration is required'),
    // is_featured: Yup.boolean(),
    // is_company_featured: Yup.boolean(),
  }
  if (!isAdmin()) {
    delete schemaObj.company_id
  }
  if (transcriptUrl && transcriptUrl != "") {
    delete schemaObj.transcript_path
  }
  return Yup.object().shape(schemaObj);
}

export const VideoForm = (props: IVideo): ReactElement => {
  const {
    title,
    summary,
    url,
    transcript_path,
    cover_image,
    transcriptUrl,
    coverUrl,
    company_id,
    category_id,
    participants,
    tags,
    video_length,
    // is_featured,
    type,
    // is_company_featured,
    onSubmit,
    isEdit
  } = props

  const [companyState] = useState(company_id)
  const [categoryState] = useState(category_id)
  const [participantsState] = useState(participants)
  const [tagsState] = useState(tags)
  // const [isCompanyFeaturedState, setIsCompanyFeaturedState] = useState(
  //   is_company_featured
  // )
  // const [isFeaturedState, setIsFeaturedState] = useState(is_featured)
  const [thumbUrl, setThumbUrl] = useState(coverUrl)
  const [isHttpUrl, setIsHttpUrl] = useState(!coverUrl || coverUrl == "")
  const VideoSchema = getSchema(transcriptUrl || "")
  useEffect(() => {
    if (isHttpUrl) {
      getCoverPath(url || '')
    }
  }, [])
  const getCoverPath = (url: string): void => {
    if (isHttpUrl && url.indexOf("?v=") != -1) {
      const vid = url.split("?v=")[1]
      setThumbUrl(`https://img.youtube.com/vi/${vid}/hqdefault.jpg`)
      setIsHttpUrl(true)
    }
  }
  return (
    <Formik
      initialValues={{
        title: title || '',
        summary: summary || '',
        url: url || '',
        transcript_path: transcript_path || null,
        cover_image: cover_image || null,
        company_id: company_id || '',
        category_id: category_id || '',
        participants: participants || [],
        tags: tags || [],
        video_length: video_length || "",
        // is_featured: is_featured || false,
        // is_company_featured: is_company_featured || false,
      }}
      validationSchema={VideoSchema}
      onSubmit={async (values, actions) => {
        try {
          const valid = await VideoSchema.isValid(values)
          if (valid) {
            const res = await onSubmit(values)
            actions.setSubmitting(false)

            if (res && !isEdit) {
              actions.resetForm({
                values: {
                  title: '',
                  summary: '',
                  url: '',
                  transcript_path: null,
                  cover_image: null,
                  company_id: 0,
                  category_id: '',
                  participants: [0],
                  tags: [0],
                  video_length: "",
                  // is_featured: false,
                  // is_company_featured: false,
                },
              })
              // window.location.reload()
            }
          }

        } catch (error) {
          console.log(error)
        }
      }}
    >
      {({
        values,
        isSubmitting,
        setFieldValue,
      }) => (
          <div className="video-form-container mb-3">
            <Form>
              <div>
                <InputTextField label="Title" name="title" placeholder="Title" />
              </div>
              <div className="form-textarea">
                <MultiTextField
                  label="Summary"
                  name="summary"
                  placeholder="Summary" />
              </div>
              <div className="form-group">
                <InputTextField
                  label="Video Url"
                  name="url"
                  placeholder="Video Url"
                  value={values.url}
                  onChange={(event: any) => {
                    setFieldValue("url", event.target.value);
                    getCoverPath(event.target.value)
                  }}
                />
                <div>
                  <MaskedInputTextField label="Video Duration"
                    name="video_length"
                    placeholder="Video Duration"
                    mask={[/\d/, /\d/, ':', /\d/, /\d/, ':', /\d/, /\d/]}
                  />
                </div>

              </div>

              <Row>
                <Col>
                  <div className="form-group upload-file-image">
                    <FileUploader
                      isHttpPath={isHttpUrl}
                      id={"cover_image"}
                      filePath={thumbUrl || ""}
                      fileType={""}
                      label="Thumbnail / Cover Image"
                      name="cover_image"
                      imageHeight={150}
                      onFileChange={(file: any) => {
                        setFieldValue("cover_image", file)
                        setIsHttpUrl(false)
                      }} />
                  </div>
                </Col>
                <Col>
                  <Row>
                    <Col lg={12}>
                      <div className="form-group">
                        <FileUploader
                          id={"transcript_path"}
                          filePath={transcriptUrl || ""}
                          fileType={"vtt"}
                          label="VTT"
                          name="transcript_path"
                          onFileChange={(file: any) => { setFieldValue("transcript_path", file) }} />
                      </div>

                    </Col>
                    <Col lg={12}>

                      <div className="form-group">
                        <Categories
                          name="category_id"
                          onChange={(value) => setFieldValue('category_id', value)}
                          selectedOptions={categoryState}
                        />
                      </div>
                    </Col>

                  </Row>
                </Col>
              </Row>

              <div className="form-group">
                {
                  isAdmin() && <Companies
                    name="company_id"
                    onChange={(value) => setFieldValue('company_id', value)}
                    selectedOptions={companyState}
                  />
                }
              </div>

              <div className="form-group">
                <Tags
                  name="tags"
                  onChange={(value) => setFieldValue('tags', value)}
                  selectedOptions={tagsState}
                />
              </div>

              <div className="form-group">
                <Participants
                  name="participants"
                  onChange={(value) => setFieldValue('participants', value)}
                  selectedOptions={participantsState}
                />
              </div>

              {/* <Checkbox
                name="is_featured"
                label="Feature on homepage"
                checked={isFeaturedState}
                onChange={() => {
                  setIsFeaturedState(!isFeaturedState)
                }}
              />
              <Checkbox
                name="is_company_featured"
                label="Feature on company page"
                checked={isCompanyFeaturedState}
                onChange={() => {
                  setIsCompanyFeaturedState(!isCompanyFeaturedState)
                }}
              /> */}
              {isSubmitting
                ?
                <Loader />
                :
                <Button variant="primary" type="submit" disabled={isSubmitting} className="mr-3">
                  {type}
                </Button>
              }
              <Button onClick={() => {
                window.history.back()
              }} variant="outline-primary">Cancel</Button>
            </Form>
          </div>
        )}
    </Formik>
  )
}
