import { execute, HTTPMethod } from './http.service'

export const users = async (queryParam = '') => {
  const query = queryParam != '' ? `${queryParam}` : ''
  const response = await execute(`protected/user/paginate${query}`)
  return response
}

export const getUserById = async (userId: number) => {
  const response = await execute(`/protected/user/${userId}`)
  return response
}

export const addUser = async (reqBody: object = {}) => {
  const response = await execute(`/protected/user`, HTTPMethod.POST, reqBody)
  return response
}
export const editUser = async (reqBody: object = {}, userId: number) => {
  const response = await execute(
    `/protected/user/${userId}`,
    HTTPMethod.PUT,
    reqBody
  )
  return response
}
export const deleteUser = async (reqBody: object = {}) => {
  const response = await execute(`/protected/user`, HTTPMethod.DELETE, reqBody)
  return response
}
export const validateUser = async (reqBody: object = {}) => {
  const response = await execute(
    `/user/validate-user`,
    HTTPMethod.POST,
    reqBody
  )
  return response
}
export const resetPassword = async (reqBody: object = {}) => {
  const response = await execute(`/user/set-password`, HTTPMethod.POST, reqBody)
  return response
}
