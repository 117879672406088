import { execute, HTTPMethod } from './http.service'
export const tags = async () => {
  const response = await execute(`protected/tags`)
  return response
}

export const addTag = async (tag: any) => {
  const response = await execute(`protected/tags`, HTTPMethod.POST, tag)
  return response
}
