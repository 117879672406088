import { ErrorMessage, Field } from 'formik'
import React, { ReactElement } from 'react'
import { Button } from 'react-bootstrap'
import { getServerUrl } from '../../../utils/helper'

interface Props {
    filePath: string
    label: string
    name: string
    fileType: string
    id?: string
    isHttpPath?: boolean
    imageWidth?: number
    imageHeight?: number
    onFileChange: (file: any) => void
}
export default class FileUploader extends React.Component<Props> {
    fileRef: any
    fileRef1: any
    state = {
        imagePath: this.props.filePath || '',
    }

    componentDidUpdate(prevProps: Props): void {
        if ((prevProps.filePath != this.props.filePath)) {
            this.setState({
                imagePath: this.props.filePath || '',
            })
        }
    }
    render(): ReactElement {
        const { label, onFileChange, name, id, isHttpPath, fileType, imageWidth, imageHeight } = this.props
        const isVTT = fileType == "vtt"
        return (
            <div>
                <Field name={name}>
                    {() => (
                        <div>
                            {
                                this.state.imagePath === '' ||
                                    this.state.imagePath.toLowerCase() === 'null' ? (
                                        <div>
                                            <input
                                                ref={(ref) => {
                                                    if (!this.fileRef1) {
                                                        this.fileRef1 = ref
                                                    }
                                                }}
                                                className="d-none"
                                                type="file"
                                                name={`fileUpload-${id}`}
                                                id={`fileUpload-${id}`}
                                                accept={isVTT ? '.vtt' : '.png, .jpg, .jpeg'}
                                                onChange={(e) => {
                                                    const file = e && e.target && e.target.files && e.target.files[0]
                                                    if (isVTT && file) {
                                                        this.setState({
                                                            imagePath: file.name
                                                        })
                                                    } else {
                                                        const localPath = file ? URL.createObjectURL(file) : null
                                                        localPath && this.setState({
                                                            imagePath: localPath,
                                                        })
                                                    }
                                                    onFileChange(file || '')
                                                }}
                                            />
                                            <label className="d-block">{label}</label>
                                            <div className="upload-file">
                                                <label htmlFor={`fileUpload-${id}`} className="uploadButton form-control">
                                                    Choose File
                                                </label>
                                                <Button onClick={() => {
                                                    this.fileRef1.click()
                                                }} variant="outline-primary" size="sm">Upload</Button>
                                            </div>

                                        </div>
                                    ) : (
                                        <div>
                                            <div className="d-none">
                                                <input
                                                    ref={(ref) => {
                                                        if (!this.fileRef) {
                                                            this.fileRef = ref
                                                        }
                                                    }}
                                                    type="file"
                                                    name={`fileUpload1-${id}`}
                                                    id={`fileUpload1-${id}`}
                                                    accept={fileType == "vtt" ? '.vtt' : '.png, .jpg, .jpeg'}
                                                    onChange={(e) => {
                                                        const file = e && e.target && e.target.files && e.target.files[0]
                                                        if (isVTT && file) {
                                                            this.setState({
                                                                imagePath: file.name
                                                            })
                                                        } else {
                                                            const localPath = file ? URL.createObjectURL(file) : null
                                                            localPath && this.setState({
                                                                imagePath: localPath,
                                                            })
                                                        }

                                                        onFileChange(file || '')
                                                    }}
                                                />

                                            </div>
                                            <label className="d-block">{label}</label>
                                            {
                                                isVTT
                                                    ?
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        className="uploadButton form-control text-primary"
                                                        onClick={() => {
                                                            this.fileRef.click()
                                                        }}>
                                                        <span>{this.state.imagePath.substr(this.state.imagePath.indexOf("/") + 1, this.state.imagePath.length)}</span>
                                                    </div>
                                                    :
                                                    <img
                                                        onClick={() => {
                                                            this.fileRef.click()
                                                        }}
                                                        style={{ width: imageWidth || 349, height: imageHeight || 250, cursor: 'pointer' }}
                                                        className="cover"
                                                        src={
                                                            this.state.imagePath.indexOf('blob:') != -1
                                                                ? this.state.imagePath
                                                                :
                                                                isHttpPath
                                                                    ?
                                                                    this.state.imagePath
                                                                    :
                                                                    getServerUrl(this.state.imagePath)
                                                        }
                                                    ></img>
                                            }
                                        </div>
                                    )
                            }
                        </div>
                    )}


                </Field>
                <div className="text-danger">
                    <ErrorMessage name={name} />
                </div>
            </div >
        )
    }
}
