import Axios, { AxiosInstance, AxiosRequestConfig } from 'axios'
import { getCookie } from '../utils/cookie'
import { TOKEN_STORAGE_KEY } from './auth.service'

// readonly properties
const dev = process.env.NODE_ENV !== 'production'

export const baseUrl = (() =>
  dev ? `http://localhost:${process.env.REACT_APP_API_PORT}` : '')()

export class HTTPMethod {
  static GET = 'get'
  static POST = 'post'
  static PUT = 'put'
  static DELETE = 'delete'
}
const http: AxiosInstance = Axios.create({
  baseURL: `${baseUrl}/api/v1/`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})
export let httpToken = ''
export const setJwt = (jwt: string): void => {
  httpToken = jwt
  Axios.defaults.headers.common['Authorization'] = jwt
}

export const execute = async (
  url: string,
  method: string = HTTPMethod.GET,
  data = {},
  config: AxiosRequestConfig = {}
): Promise<any> => {
  try {
    if (url.indexOf('protected') !== -1 || url.indexOf('admin') !== -1) {
      setJwt(getCookie(TOKEN_STORAGE_KEY))
      if (!config['headers']) {
        config.headers = {}
      }
      if (httpToken !== '') {
        config.headers['Authorization'] = httpToken
      }
    }
    let response
    let httpMethod = http.post
    switch (method) {
      case HTTPMethod.POST:
        httpMethod = http.post
        break
      case HTTPMethod.PUT:
        httpMethod = http.put
        break
      case HTTPMethod.DELETE:
        httpMethod = http.delete
        break
    }
    if (method === HTTPMethod.GET) {
      response = await http.get(url, config)
    } else if (method === HTTPMethod.DELETE) {
      const headers = {
        Authorization: config.headers['Authorization'],
      }
      response = await Axios({
        url: `${baseUrl}/api/v1${url}`,
        headers,
        data,
        method: 'DELETE',
      })
    } else {
      response = await httpMethod(url, data, config)
    }
    console.log('response', response)
    return response.data
  } catch (e) {
    const response = e.response
    console.log('error', e, response)
    if (response && response.status !== 500) {
      return {
        error: true,
        message: response.data.message || 'Something went wrong',
      }
    }
    return { error: true, message: 'Something went wrong' }
  }
}
