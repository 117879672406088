import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { ProfileForm } from '../../../components/forms/profile';
import Layout from '../../../components/layout';
import { uploadFile } from '../../../services/file.service';
import { editUser, getUserById } from '../../../services/user.service';
import { showError, showSuccess } from '../../../utils/helper';
import { USER_STORAGE_KEY } from '../../../services/auth.service';
import { getCookie } from '../../../utils/cookie';

export const ProfileEdit: React.FunctionComponent = (props: any) => {
    const history = useHistory()
    const { id: userId } = JSON.parse(getCookie(USER_STORAGE_KEY))
    let fileId = 0
    const [fetched, setFetched] = useState(false)
    const [company, setCompany] = useState([])

    const [user, setUser] = useState({
        "id": 0,
        "is_active": 0,
        "slug": null,
        "firstName": "",
        "lastName": "",
        "profile": { "path": "" },
        "email": "",
        "role": "",
    })
    useEffect(() => {
        getUser()
    }, [userId])
    const getUser = async () => {
        const response = await getUserById(userId)
        console.log(userId, "reponse===", userId)

        if (!response.error) {
            setUser(response.data)
            setCompany(getCompaniesData(response.data.UserCompanies))
        } else {
            showError(response.message)
        }
        setFetched(true)
    }

    const getCompaniesData = (participants: any) => {
        if (participants.length < 1) {
            return []
        }

        const participantsArray = participants.map((element: any) => {
            return element.company_id
        })

        return participantsArray
    }

    const formSubmit = async (data: any) => {
        if (fileId == 0 && data.profilePic && data.profilePic instanceof File) {
            const formData = new FormData()
            formData.set('file', data.profilePic)
            const fileResponse = await uploadFile(formData)
            if (!fileResponse.error) {
                fileId = fileResponse.data.data.id

            } else {
                showError("File upload failed")
                return false
            }

        }

        if (fileId != 0) {
            data.profilePic = fileId
        } else {
            delete data.profilePic
        }

        if (data.role == "admin") {
            delete data.company_id
            data.role = "admin"
        }

        delete data.password
        if (data.oldPassword != "" && data.newPassword != "" && data.confirmPassword != "") {
            data["reset_password"] = {
                oldPassword: data.oldPassword,
                newPassword: data.newPassword,
                confirmPassword: data.confirmPassword
            }
        }
        // delete data.oldPassword
        // delete data.newPassword
        // delete data.confirmPassword
        const response = await editUser(data, userId)
        if (!response.error) {
            showSuccess("Profile updated successfully")
            setTimeout(() => {
                if (data.reset_password) {
                    history.push({
                        pathname: '/logout'
                    })
                } else {
                    history.push({
                        pathname: '/users/list'
                    })
                }
            }, 100)
            return true
        } else {
            showError(response.message)
            return false
        }
    }
    return (
        <Layout>
            <Container className="w-50">
                <h2 className="mb-5">{`Edit Profile`}</h2>
                {
                    fetched && <ProfileForm
                        type="Update"
                        isEdit={true}
                        firstName={user.firstName || ''}
                        lastName={user.lastName || ''}
                        email={user.email || ''}
                        role={user.role || ''}
                        serverUrl={user.profile && user.profile["path"] || ""}
                        company_id={company}
                        onSubmit={async (formValues) => await formSubmit(formValues)} ></ProfileForm>
                }


            </Container>
        </Layout>
    )

}
