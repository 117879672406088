import React, { FunctionComponent, useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { CompanyForm } from '../../../components/forms/company'
import Layout from '../../../components/layout'
import {
  getCompanyById,
  updateCompany,
  updateCompanyAndCreateUser,
} from '../../../services/company.service'
import { uploadFile } from '../../../services/file.service'
import { isAdmin } from '../../../services/role.service'
import { showError, showSuccess } from '../../../utils/helper'

export const EditCompany: FunctionComponent = (props: any) => {
  const history = useHistory()
  let profileId = 0,
    headerId = 0,
    mobileHeaderId = 0
  const { id } = props.match.params
  const [fetched, setFetched] = useState(false)
  const [companyData, setCompanyData] = useState({
    id: '',
    name: '',
    description: '',
    profile: { path: '' },
    header: { path: '' },
    mobile_header: { path: '' },
    email: '',
    phone: '',
    website: '',
  })

  const [companyUserData, setCompanyUserData] = useState({
    id: '',
    firstName: '',
    lastName: '',
    email: '',
  })

  useEffect(() => {
    getCompany()
  }, [id])
  const getCompany = async () => {
    const response = await getCompanyById(id)
    if (!response.error) {
      setCompanyData(response.data)
      const user = response.data.CompanyUsers.find(
        (item: any) => item.user && item.user.id
      )
      if (user && user.user) {
        setCompanyUserData(user.user)
      }
      // setCompanyUserData(user)
    } else {
      showError(response.message)
    }
    setFetched(true)
  }
  const onFormSubmit = async (values: any) => {
    if (
      profileId == 0 &&
      values.profile_image &&
      values.profile_image instanceof File
    ) {
      const formData = new FormData()
      formData.set('file', values.profile_image)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        profileId = fileResponse.data.data.id
      } else {
        showError('File upload failed')
        return false
      }
    }

    if (
      headerId == 0 &&
      values.header_image &&
      values.header_image instanceof File
    ) {
      const formData = new FormData()
      formData.set('file', values.header_image)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        headerId = fileResponse.data.data.id
      } else {
        showError('File upload failed')
        return false
      }
    }

    if (
      mobileHeaderId == 0 &&
      values.mobile_header_image &&
      values.mobile_header_image instanceof File
    ) {
      const formData = new FormData()
      formData.set('file', values.mobile_header_image)
      const fileResponse = await uploadFile(formData)
      if (!fileResponse.error) {
        mobileHeaderId = fileResponse.data.data.id
      } else {
        showError('File upload failed')
        return false
      }
    }

    const reqData = {
      company: {
        id: companyData.id,
        name: values.name,
        description: values.description,
        profile_image: values.profile_image,
        header_image: values.header_image,
        mobile_header_image: values.mobile_header_image,
        email: values.email,
        phone: values.phone,
        website: values.website,
      },
      user: {
        id: companyUserData.id,
        firstName: values.firstName,
        lastName: values.lastName,
        role: 'company_manager',
        email: values.userEmail,
      },
    }
    if (profileId != 0) {
      reqData.company['profile_image'] = profileId
    } else {
      delete reqData.company['profile_image']
    }

    if (headerId != 0) {
      reqData.company.header_image = headerId
    } else {
      delete reqData.company.header_image
    }

    if (mobileHeaderId != 0) {
      reqData.company.mobile_header_image = mobileHeaderId
    } else {
      delete reqData.company.mobile_header_image
    }
    let response
    if (!reqData.user.id) {
      response = await updateCompanyAndCreateUser(reqData)
    } else {
      response = await updateCompany(reqData)
    }

    if (!response.error) {
      showSuccess('Company updated successfully')
      headerId = 0
      profileId = 0
      setTimeout(() => {
        isAdmin()
          ? history.push({
              pathname: '/company/list',
            })
          : history.goBack()
      }, 100)
      return true
    } else {
      showError(response.message)
      return false
    }
  }

  return (
    <Layout>
      <Container className="w-50 mr-auto">
        <h2 className="mb-5">Edit a Company</h2>
        {fetched && (
          <CompanyForm
            email={companyData.email || ''}
            description={companyData.description || ''}
            profileUrl={
              (companyData.profile && companyData.profile['path']) || ''
            }
            headerUrl={(companyData.header && companyData.header['path']) || ''}
            mobileHeaderImage={
              (companyData.mobile_header &&
                companyData.mobile_header['path']) ||
              ''
            }
            name={companyData.name || ''}
            phone={companyData.phone || ''}
            website={companyData.website || ''}
            firstName={companyUserData?.firstName || ''}
            lastName={companyUserData?.lastName || ''}
            userEmail={companyUserData?.email || ''}
            onSubmit={async (values) => await onFormSubmit(values)}
            type="Update"
            isEdit={true}
          />
        )}
      </Container>
    </Layout>
  )
}
