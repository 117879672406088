import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { AccountForm } from '../../components/forms/account';
import Layout from '../../components/layout';
import Loader from '../../components/loader';
import { resetPassword, validateUser } from '../../services/user.service';
import { showError } from '../../utils/helper';

export const AccountActivation: React.FunctionComponent = (props: any) => {
    const { token } = props.match.params
    const [success, setSuccess] = useState(false)
    const [isValidating, setIsValidating] = useState(true)
    const [error, setError] = useState("")
    const [user, setUser] = useState({
        "id": 13,
        "firstName": "",
        "lastName": "",
        "profilePic": null,
        "email": "",
    })
    useEffect(() => {
        validateToken()
    }, [token])

    const validateToken = async () => {
        const res = await validateUser({
            "token": token
        })
        if (!res.error) {
            setUser(res.data)
            setError('')
            setIsValidating(false)
        } else {
            setIsValidating(false)
            setError(res.message)
        }
    }

    const formSubmit = async (data: any) => {
        data.token = token
        const response = await resetPassword(data)
        if (!response.error) {
            setSuccess(true)
            return true
        } else {
            setSuccess(false)
            showError(response.message)
            return false
        }

    }
    return (
        <Layout>
            {console.log(user)}
            <Container className="w-50 mr-auto container" >

                {
                    isValidating
                        ?
                        <div>
                            <p>Validating token, please wait...</p>
                            <Loader />
                        </div>
                        :
                        error != "" ?
                            <p>{error}</p>
                            :
                            <div>
                                <h2 className="mb-5">Complete Your Registration</h2>
                                {
                                    !success
                                        ?
                                        <AccountForm
                                            firstName={user.firstName}
                                            lastName={user.lastName}
                                            email={user.email}
                                            onSubmit={async (formValues) => await formSubmit(formValues)}></AccountForm>
                                        :
                                        <p>Account updated successfully. <a href="/login">Click Here</a> to login.</p>

                                }
                            </div>
                }


            </Container>
        </Layout >
    )

}
